import { Box } from '@rebass/grid';
import { gql } from '@apollo/client';
import range from 'lodash.range';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import searchParams from '../constants/searchParams';
import { UnitSearchParamsInput, useUnitRelevantAlternativesQuery } from '../generated/graphql';
import useQueryParams from '../hooks/useQueryParams';
import { pickBookingFromParams } from '../utils/trip';
import Carousel from './designsystem/Carousel';
import { useTileImageSizes } from './designsystem/Tile';
import { UnitSection } from './pages/UnitDetail';
import SearchHit from './SearchHit';
import useAnalyticsUnitViewList from '../hooks/analytics/useAnalyticsUnitViewList';
import ContentWrapper from './ContentWrapper';
import { useTenantContext } from '../context/TenantContext';

export const unitRelevantAlternativesQuery = gql`
    query UnitRelevantAlternatives(
        $slug: String!
        $params: UnitSearchParamsInput
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id

            relevantAlternatives(params: $params) {
                ...UnitRelevantAlternatives
            }
        }
    }

    fragment UnitRelevantAlternatives on UnitSearchResult {
        hits {
            ...SearchHit
        }
    }
`;

const UnitRelevantAlternatives = ({ params }: { params: UnitSearchParamsInput | null }) => {
    const [query] = useQueryParams(searchParams);
    const router = useRouter();
    const sizes = useTileImageSizes();
    const [ref, inView] = useInView({ triggerOnce: true });
    const { brandConfig } = useTenantContext();
    const { data, loading } = useUnitRelevantAlternativesQuery({
        skip: !inView,
        variables: {
            params: params?.date && params.duration ? params : null,
            slug: router.query.slug as string,
            badgeHandle: brandConfig.badge?.handle ?? '',
            badge2Handle: brandConfig.badge2?.handle ?? '',
        },
        ssr: false,
    });

    const unitParams = useMemo(() => pickBookingFromParams(query), [query]);
    const hideAlternatives =
        data?.rentalUnit?.relevantAlternatives && !data.rentalUnit.relevantAlternatives.hits.length;
    useAnalyticsUnitViewList(data?.rentalUnit?.relevantAlternatives?.hits ?? undefined);

    return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <UnitSection ref={ref as any} name="alternatives">
            {!hideAlternatives && (
                <ContentWrapper>
                    <Carousel
                        continuous
                        title={<FormattedMessage defaultMessage="Accommodaties die u misschien ook leuk vindt" />}
                    >
                        {loading &&
                            range(4).map(i => (
                                <Container key={i}>
                                    <Wrapper>
                                        <SearchHit loading />
                                    </Wrapper>
                                </Container>
                            ))}
                        {!loading &&
                            data?.rentalUnit?.relevantAlternatives?.hits.map(hit => (
                                <Container key={hit.rentalUnit.id}>
                                    <Wrapper>
                                        <SearchHit
                                            sizes={sizes}
                                            unitParams={unitParams}
                                            rentalUnit={hit.rentalUnit}
                                            trips={hit.trips}
                                            loading={false}
                                        />
                                    </Wrapper>
                                </Container>
                            ))}
                    </Carousel>
                </ContentWrapper>
            )}
        </UnitSection>
    );
};

export default UnitRelevantAlternatives;

const Container = styled(Box)`
    scroll-snap-align: start;

    & + & {
        margin-left: 3.2rem;
    }

    &:last-child {
        margin-right: 3.2rem;
    }
`;

const Wrapper = styled(Box)`
    width: 32rem;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 44.8rem;
    }

    > a > div {
        scroll-snap-align: none;
    }
`;
