import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Link from 'next/link';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BooleanParam } from 'use-query-params';
import searchParams from '../constants/searchParams';
import useQueryParams from '../hooks/useQueryParams';
import { UnitDetailsRentalUnit } from '../hooks/useUnitDetailData';
import { pickBookingFromParams } from '../utils/trip';
import ContentWrapper from './ContentWrapper';
import Carousel from './designsystem/Carousel';
import { useTileImageSizes } from './designsystem/Tile';
import SearchHit from './SearchHit';
import useAnalyticsUnitViewList from '../hooks/analytics/useAnalyticsUnitViewList';

interface Props {
    rentalUnit: NonNullable<UnitDetailsRentalUnit>;
    loading: boolean;
}

const UnitAlternatives: FC<React.PropsWithChildren<Props>> = ({ rentalUnit, loading }) => {
    const [urlParams] = useQueryParams({ ...searchParams, withHero: BooleanParam });
    const didSearch = Object.keys(urlParams).length !== 0;
    const sizes = useTileImageSizes();
    const { hits = [] } = rentalUnit?.alternativesInAccommodation ?? {};
    useAnalyticsUnitViewList(hits);

    return (
        <>
            <ContentWrapper>
                <Carousel
                    title={
                        <FormattedMessage
                            defaultMessage="Andere opties bij {name}"
                            values={{ name: rentalUnit.accommodation.name }}
                        />
                    }
                    continuous
                >
                    {hits.length > 0 &&
                        hits.map(hit => (
                            <SearchHitWrapper key={hit.rentalUnit.id}>
                                <SearchHit
                                    loading={loading}
                                    rentalUnit={hit.rentalUnit}
                                    trips={hit.trips}
                                    showPrice={didSearch}
                                    unitParams={pickBookingFromParams(urlParams)}
                                    sizes={sizes}
                                />
                            </SearchHitWrapper>
                        ))}
                </Carousel>
                {rentalUnit.accommodation.hasPublicPage && (
                    <StyledContentWrapper>
                        <Link href={`/complex/${rentalUnit.accommodation.slug}`} passHref legacyBehavior>
                            <Button as="a" style={{ cursor: 'pointer' }} variant="outline">
                                <FormattedMessage
                                    defaultMessage="Bekijk {name}"
                                    values={{ name: rentalUnit.accommodation.name }}
                                />
                            </Button>
                        </Link>
                    </StyledContentWrapper>
                )}
            </ContentWrapper>
        </>
    );
};

export default UnitAlternatives;

const StyledContentWrapper = styled(ContentWrapper)`
    padding-top: 0;
    display: flex;
    --wrapperPadding: 0;
`;

const SearchHitWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: ${({ theme }) => theme.spacing['50_Semi']};
    width: 32rem;
    scroll-snap-align: start;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 44.8rem;
        height: 100%;
    }
`;
