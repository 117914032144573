import Img, { BaseImgProps } from './Img';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Separator from '@oberoninternal/travelbase-ds/components/section/Separator';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import TranslatedIcon from '@oberoninternal/travelbase-ds/svg/Translated.svg';
import React, { FC } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { UnitReviewsFragment } from '../generated/graphql';
import Grid from './designsystem/Grid';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';

interface Props {
    className?: string;
    translationNote?: string;
    review: UnitReviewsFragment;
    ownerName: Maybe<string>;
    ownerImage: Maybe<BaseImgProps>;
    hideRating?: boolean;
}

const Review: FC<React.PropsWithChildren<Props>> = ({
    review: { average, comment, title, date, reply, city },
    className,
    translationNote,
    ownerName,
    ownerImage,
    hideRating = false,
}) => (
    <Container className={className}>
        <RatingGrid>
            {!hideRating && (
                <Rating>
                    <Title>
                        <FormattedNumber value={average} style="decimal" />
                    </Title>
                </Rating>
            )}

            <RatingSummary>
                <ReviewTitle>{title}</ReviewTitle>
                <Byline>
                    {city && `${city}, `}
                    <FormattedDate value={new Date(date)} month="long" year="numeric" />
                </Byline>
            </RatingSummary>
        </RatingGrid>
        <Grid>
            <Content>
                <Text>{comment}</Text>

                {translationNote && (
                    <TranslationNote>
                        <TranslatedIcon />
                        <span>{translationNote}</span>
                    </TranslationNote>
                )}

                {reply && (
                    <>
                        <Separator />

                        <Response>
                            {ownerImage && (
                                <OwnerAvatarWrapper>
                                    <Img {...ownerImage} layout="fill" />
                                </OwnerAvatarWrapper>
                            )}
                            <Reply>
                                <Byline>
                                    {ownerName ? (
                                        <>
                                            <FormattedMessage defaultMessage="Reactie van" /> {ownerName}{' '}
                                        </>
                                    ) : (
                                        <FormattedMessage defaultMessage="Reactie gastheer" />
                                    )}
                                </Byline>
                                <Body>{reply}</Body>
                            </Reply>
                        </Response>
                    </>
                )}
            </Content>
        </Grid>
    </Container>
);

const Response = styled.div`
    display: flex;
    > * + * {
        margin-left: ${({ theme }) => theme.spacing['40_Standard']};
    }
`;

const Reply = styled.div`
    img + & {
        margin-left: ${({ theme }) => theme.spacing['40_Standard']};
    }
`;

const ReviewTitle = styled(Body).attrs({ variant: 'large' })`
    font-weight: 700;
`;

const OwnerAvatarWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
    flex-shrink: 0;
`;

const TranslationNote = styled(Label).attrs({ variant: 'small' })`
    display: flex;
    margin-top: 2.4rem;
    align-items: center;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.neutral['30']};

    svg + * {
        margin-left: 1.4rem;
    }
`;

const Byline = styled(Body).attrs({ variant: 'tiny' })`
    font-weight: 400;
`;

const Container = styled.div``;

const RatingGrid = styled(Grid)`
    display: flex;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: grid;
    }
`;

const Content = styled.div`
    padding-top: ${({ theme }) => theme.spacing['40_Standard']};
    grid-column: 1 / -1;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 100%;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        grid-column: 2 / -1;
    }
`;

const Text = styled.div`
    * + & {
        margin-top: 2.7rem;
    }
`;

const Rating = styled.div`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 5.6rem;
    max-width: 5.6rem;
    margin-right: 1.6rem;
    width: 100%;
    color: ${({ theme }) =>
        theme.colorAssignments.rating
            ? getAssignmentColor(theme, theme.colorAssignments.rating)
            : theme.colors.secondary['30']};
    grid-column: 1;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        justify-self: center;
        margin-right: 0;
        max-width: 6.4rem;
    }

    :before {
        width: 100%;
        content: '';
        padding-top: 100%;
        background-color: ${({ theme }) =>
            theme.colorAssignments.rating
                ? getAssignmentColor(theme, theme.colorAssignments.rating)
                : theme.colors.secondary['30']};
        border-radius: 50%;
        position: absolute;
        margin-top: -2%;
        z-index: -1;
        opacity: 0.05;
    }
`;

const RatingSummary = styled.div`
    grid-column: 2 / -1;
`;

export default Review;
