import React, { FC, ReactNode } from 'react';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { FormattedMessage } from 'react-intl';
import PhotoCategoryGallery from './PhotoCategoryGallery';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import ContentWrapper from './ContentWrapper';
import { ImagesFragment } from '../generated/graphql';

export const fragment = gql`
    fragment Images on AccommodationImage {
        category
        jpegTile: transform(config: TILE, format: JPEG) {
            ...Transform
        }
        webpTile: transform(config: TILE, format: WEBP) {
            ...Transform
        }
        jpegGallery: transform(config: GALLERY, format: JPEG) {
            ...Transform
        }
        webpGallery: transform(config: GALLERY, format: WEBP) {
            ...Transform
        }
    }

    fragment Transform on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }
`;

export const unitPhotosFragment = gql`
    fragment UnitPhotos on RentalUnit {
        images {
            ...Images
        }
    }
`;

export const accommodationPhotos = gql`
    fragment AccommodationPhotos on Accommodation {
        images {
            ...Images
        }
    }
`;

interface Props {
    data: ImagesFragment[];
    onCategoryClick: (categoryIndex: number) => void;
    onOpenPhotos: () => void;
    title?: ReactNode;
}

const UnitPhotos: FC<React.PropsWithChildren<Props>> = ({ onOpenPhotos, ...props }) => (
    <Container>
        <PhotoCategoryGallery {...props} />
        <StyledWrapper>
            <Button variant="outline" onClick={onOpenPhotos}>
                <FormattedMessage defaultMessage="Bekijk alle ruimtes" />
            </Button>
        </StyledWrapper>
    </Container>
);

const Container = styled.div`
    display: none;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        display: block;
    }
`;

const StyledWrapper = styled(ContentWrapper)`
    padding-top: 0;
    --wrapperPadding: 0;
`;

export default UnitPhotos;
