import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Flex } from '@rebass/grid';
import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ExtrasFragment } from '../generated/graphql';
import ContentWrapper from './ContentWrapper';
import Heading from './designsystem/Heading';

const getUnitAttributes = (attributeCategories: ExtrasFragment[]) =>
    attributeCategories
        .map(attributeCategory => ({
            ...attributeCategory,
            attributes: attributeCategory.attributes,
        }))
        .filter(category => category.attributes.length > 0);

interface Props {
    data: ExtrasFragment[];
    name: string;
    registrationNumber?: string | null;
}

const UnitAttributes: FC<React.PropsWithChildren<Props>> = ({ data, name, registrationNumber }) => {
    const { open, onToggle } = useSesame(false);
    const ref = useRef<HTMLButtonElement>(null);

    return (
        <StyledWrapper>
            <Heading>
                <Title>
                    <FormattedMessage defaultMessage="Kenmerken {name}" values={{ name }} />
                </Title>
            </Heading>
            <Container open={open}>
                <StyledGrid>
                    {getUnitAttributes(data).map(category => (
                        <CategoryAttributes category={category} key={category.name} />
                    ))}
                </StyledGrid>
                {registrationNumber && (
                    <StyledFlex alignItems="center">
                        <StyledBody variant="small" elementType="h3">
                            <FormattedMessage defaultMessage="Gemeentelijk registratienummer:" /> &nbsp;
                        </StyledBody>
                        {registrationNumber}
                    </StyledFlex>
                )}
            </Container>
            <StyledButton
                ref={ref}
                variant="outline"
                onClick={() => {
                    if (open) {
                        onToggle();
                        setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 150);
                    } else {
                        onToggle();
                    }
                }}
            >
                {open ? (
                    <FormattedMessage defaultMessage="Lees minder" />
                ) : (
                    <FormattedMessage defaultMessage="Lees meer" />
                )}
            </StyledButton>
        </StyledWrapper>
    );
};

const CategoryAttributes = ({ category }: { category: ReturnType<typeof getUnitAttributes>[number] }) => {
    const { onOpen, open, onClose } = useSesame();
    const hasMore = category.attributes.length > 5;
    return (
        <Category key={category.name} break={category.attributes.length > 6}>
            <ColumnTitle variant="small" elementType="h3">
                {category.name}
            </ColumnTitle>
            <List>
                {category.attributes.slice(0, 5).map(attribute => (
                    <Attribute key={attribute.name} variant="regular" elementType="li">
                        {attribute.name}
                    </Attribute>
                ))}
                {hasMore && (
                    <More>
                        <MoreTextButton onClick={onOpen}>
                            <FormattedMessage defaultMessage="Lees meer" />
                        </MoreTextButton>
                        <Modal variant="small" open={open} onClose={onClose}>
                            <ModalHeader style={{ marginBottom: '1.6rem' }}>{category.name}</ModalHeader>
                            {category.attributes.map(attribute => (
                                <Attribute key={attribute.name} variant="regular" elementType="li">
                                    {attribute.name}
                                </Attribute>
                            ))}
                        </Modal>
                    </More>
                )}
            </List>
        </Category>
    );
};
const StyledBody = styled(Body)`
    font-weight: 800;
    display: inline-block;
`;
const Container = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: ${({ open }) => (open ? 'none' : '16rem')};
    overflow: ${({ open }) => (open ? 'initial' : 'hidden')};
    position: relative;

    ${({ open }) =>
        !open &&
        `
        ::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5.6rem;
            width: 100%;
            background: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
        }`}

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        max-height: none;
        ::after {
            content: none;
        }
    }
`;

const StyledGrid = styled.div`
    columns: 2;
    column-gap: 3.2rem;
    position: relative;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        columns: 3;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        columns: 6;
    }
`;

const Category = styled.div<{ break: boolean }>`
    position: relative;
    padding-bottom: ${({ theme }) => theme.spacing['50_Semi']};
    break-inside: avoid;
    page-break-inside: avoid;
`;

const List = styled.ul`
    padding: 0;
    margin: 0;
    display: inline;
`;

const StyledButton = styled(Button)`
    margin-top: 2.2rem;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const StyledWrapper = styled(ContentWrapper)`
    padding-top: 0;
`;

const ColumnTitle = styled(Body)`
    margin-bottom: ${({ theme }) => theme.spacing['20_Tiny']};
    font-weight: 800;
    display: inline-block;
`;

const StyledFlex = styled(Flex)`
    width: auto !important;
`;
const Attribute = styled(Body)`
    margin-bottom: ${({ theme }) => theme.spacing['20_Tiny']};
    display: inline-block;
    width: 100%;

    &:first-child {
        break-before: avoid-column;
    }
`;

const More = styled.li`
    break-before: avoid-column;
    display: inline-block;
    width: 100%;
`;

const MoreTextButton = styled(TextButton)`
    color: ${({ theme }) => theme.colorAssignments.textAction};
`;

export default UnitAttributes;
