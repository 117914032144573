import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { BaseImgProps } from './Img';
import Separator from '@oberoninternal/travelbase-ds/components/section/Separator';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { gql } from '@apollo/client';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
    ReviewsQuery,
    ReviewsQueryVariables,
    ReviewStatsFragment,
    UnitReviewsFragment,
    useReviewsLazyQuery,
} from '../generated/graphql';
import Cell from './designsystem/Cell';
import Grid from './designsystem/Grid';
import Review from './Review';
import ReviewStats from './ReviewStats';

export const query = gql`
    query Reviews($slug: String!, $limit: Int!, $offset: Int) {
        rentalUnit(slug: $slug) {
            id
            reviews(limit: $limit, offset: $offset) {
                ...UnitReviews
            }
        }
    }

    fragment UnitReviews on Review {
        id
        title
        average
        city
        date
        comment
        reply
    }
`;

interface Props {
    reviewStats: ReviewStatsFragment;
    reviews: UnitReviewsFragment[];
    slug: string;
    ownerImage: Maybe<BaseImgProps>;
    ownerName: Maybe<string>;
    className?: string;
}

const INITIAL_OFFSET = 3;
const UnitReviews: FC<React.PropsWithChildren<Props>> = ({
    reviewStats,
    slug,
    ownerImage,
    ownerName,
    className,
    reviews,
}) => {
    const [isLoadMoreVisible, setLoadMoreVisible] = useState(reviewStats && reviewStats.count > 3);
    const [fetch, { data: reviewData, loading, fetchMore, called }] = useReviewsLazyQuery({
        variables: {
            slug,
            limit: 5,
            offset: INITIAL_OFFSET,
        },
        notifyOnNetworkStatusChange: true,
    });

    const onLoadMoreReviews = useCallback(() => {
        if (loading) {
            return;
        }

        const variables = { offset: INITIAL_OFFSET + (reviewData?.rentalUnit?.reviews.length ?? 0) };
        if (!called) {
            fetch();
        } else {
            if (!fetchMore) {
                return;
            }
            fetchMore?.<ReviewsQuery, ReviewsQueryVariables>({
                variables,
                updateQuery: (prev, { fetchMoreResult }): ReviewsQuery => {
                    if (!fetchMoreResult?.rentalUnit) {
                        return prev;
                    }

                    const prevReviews = prev.rentalUnit?.reviews ?? [];
                    const moreReviews = fetchMoreResult.rentalUnit.reviews ?? [];
                    const newReviews = [...prevReviews, ...moreReviews];

                    if (newReviews.length === reviewStats?.count) {
                        setLoadMoreVisible(!isLoadMoreVisible);
                    }

                    return {
                        ...prev,
                        rentalUnit: {
                            ...fetchMoreResult.rentalUnit,
                            ...prev.rentalUnit,
                            reviews: newReviews,
                        },
                    };
                },
            });
        }
    }, [loading, reviewData, called, fetch, fetchMore, reviewStats, isLoadMoreVisible]);

    return (
        <Cell gridColumn={['1 / -1', null, null, 'span 5', null, 'span 6']}>
            <Container className={className}>
                <ReviewStats
                    title={<FormattedMessage defaultMessage="Beoordelingen van gasten" />}
                    reviewStats={reviewStats}
                />
                <ReviewsContainer>
                    {[...reviews, ...(reviewData?.rentalUnit?.reviews ?? [])].map(review => (
                        <Fragment key={review.id}>
                            <StyledReview review={review} ownerImage={ownerImage} ownerName={ownerName} />
                            <ReviewSeparator />
                        </Fragment>
                    ))}
                </ReviewsContainer>
                <ButtonGrid>
                    <div>
                        <StyledButton
                            variant="outline"
                            onClick={onLoadMoreReviews}
                            isVisible={isLoadMoreVisible}
                            submitting={loading}
                        >
                            <FormattedMessage defaultMessage="Bekijk meer beoordelingen" />
                        </StyledButton>
                    </div>
                </ButtonGrid>
            </Container>
        </Cell>
    );
};

const StyledReview = styled(Review)``;

const ReviewSeparator = styled(Separator)`
    border: none;
    margin: 3.2rem 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }

    &:last-child {
        display: none;
    }
`;

const Container = styled.div`
    @media (max-width: ${({ theme }) => theme.mediaQueries.l}) and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        max-width: 56.8rem;
    }
`;

const ReviewsContainer = styled.div`
    margin-top: 3.2rem;

    > ${StyledReview} {
        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-top: 5.6rem;

            &:first-child {
                margin-top: 0;
            }
        }
    }
`;

const StyledButton = styled(Button)<{ isVisible: boolean }>`
    margin: 7.26% 0;

    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const ButtonGrid = styled(Grid)`
    > div {
        grid-column: 1 / -1;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        > div {
            grid-column: 2 / -1;
        }
    }
`;

export default UnitReviews;
