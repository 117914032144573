import styled from 'styled-components';
import { Link } from 'react-scroll';
import React, { FC, ReactNode, useState } from 'react';
import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import { useTenantContext } from '../../context/TenantContext';

interface Props extends Omit<ReactScrollLinkProps, 'to'> {
    items: Array<{
        name: string;
        label: ReactNode;
        className?: string;
    }>;
    className?: string;
}

const Nav: FC<React.PropsWithChildren<Props>> = ({ items, className, ...props }) => {
    const [active, setActive] = useState<string | null>(null);
    const { brandConfig } = useTenantContext();

    const scrollConfig: Omit<ReactScrollLinkProps, 'to'> = {
        smooth: true,
        spy: true,
        duration: 500,
        onSetActive(to) {
            setActive(to);
        },
        offset: brandConfig.navigation?.offset,
        ...props,
    };

    return (
        <Inner className={className}>
            {items.map((item, index) => (
                <NavItem key={index} className={item.className}>
                    <NavItemLink
                        {...scrollConfig}
                        className={active === item.name || (index === 0 && active === null) ? 'active' : ''}
                        to={item.name}
                    >
                        {item.label}
                    </NavItemLink>
                </NavItem>
            ))}
        </Inner>
    );
};

export default Nav;

const NavItem = styled.li`
    margin: 0;
`;

const Inner = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    > ${NavItem} + ${NavItem} {
        margin-left: 2.4rem;
    }

    @media screen and (min-width: 1300px) {
        > ${NavItem} + ${NavItem} {
            margin-left: 4rem;
        }
    }
`;

const NavItemLink = styled(Link)`
    color: ${({ theme }) => theme.colors.neutral['60']} !important;
    text-decoration: none !important;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    &::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['50']};
        padding-bottom: 0.2rem;
        transform-origin: 100% 0%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s, opacity 0.3s;
    }
    &:hover {
        ::before {
            opacity: 0.4;
            transform-origin: 0% 50%;
            transform: scale3d(1, 1, 1);
        }
    }
    &.active {
        color: ${({ theme }) => theme.colors.neutral['80']} !important;
        transition: color 0.3s ease-in-out;
        ::before {
            opacity: 1;
            transform-origin: 0% 0%;
            transform: scale3d(1, 1, 1);
        }
    }
`;
