import React, { FC } from 'react';
import Cell from './designsystem/Cell';
import Grid from './designsystem/Grid';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Heading from './designsystem/Heading';
import AttributeTile from '@oberoninternal/travelbase-ds/components/misc/AttributeTile';
import AttributeGrid from '@oberoninternal/travelbase-ds/components/layout/AttributeGrid';
import TextClamp from './designsystem/TextClamp';
import { gql } from '@apollo/client';
import { ExtrasFragment } from '../generated/graphql';
import ContentWrapper from './ContentWrapper';
import { useTenantContext } from '../context/TenantContext';

interface Props {
    description: string;
    data: ExtrasFragment[];
}

export const fragment = gql`
    fragment Extras on AttributeCategory {
        name
        handle
        attributes {
            id
            name
            iconUrl
            handle
        }
    }
`;

const getUnitExtras = (attributeCategories: ExtrasFragment[]) =>
    attributeCategories.map(attributeCategory => ({
        ...attributeCategory,
        attributes: attributeCategory.attributes.filter(attribute => attribute.iconUrl !== null),
    }));

const Extras: FC<React.PropsWithChildren<Props>> = ({ data, ...props }) => {
    const intl = useIntl();
    const { brandConfig } = useTenantContext();

    const extrasWithIcon = getUnitExtras(data);
    let { description } = props;
    const tiles = extrasWithIcon
        .map(attributeCategory =>
            attributeCategory.attributes.map(attribute => (
                <AttributeTile
                    key={attribute.name}
                    title={attribute.name}
                    label={attributeCategory.name}
                    icon={
                        attribute.iconUrl && (
                            <img
                                style={{ filter: brandConfig.unitExras?.svgFilter }}
                                src={attribute.iconUrl}
                                alt={`${attribute.name} icon`}
                            />
                        )
                    }
                />
            ))
        )
        .flat();

    // Yes this is depending on a hardcoded attribute, but it's not going to change they said and only applies to Texel (obviously)
    if (extrasWithIcon.some(category => category.attributes.some(attribute => attribute.handle === 'zotexels'))) {
        description = description.concat(
            `\n\n${intl.formatMessage({
                defaultMessage:
                    "Dit is een ‘ZoTexels’ accommodatie. 'ZoTexels' is het kenmerk van accommodaties van particuliere verhuurders. De authentieke sfeer en échte Texelse gastvrijheid staat hier centraal.",
            })}`
        );
    }
    // Yes this is depending on a hardcoded attribute, but it's not going to change they said and only applies to Texel (obviously)
    if (extrasWithIcon.some(category => category.attributes.some(attribute => attribute.handle === 'book-local'))) {
        description = description.concat(
            `\n\n${intl.formatMessage({
                defaultMessage:
                    'Op Texel zorgen we graag goed voor onze bezoekers en voor elkaar. Texelse ondernemers gebruiken lokale producten en diensten om jouw verblijf onvergetelijk en ‘echt Texels’ te maken. Via VVV Texel boeken betekent dat jouw geld bij Texelse bedrijven terecht komt en wordt besteed aan het uitbouwen  en promoten van de unieke en lokale Texelse economie.',
            })}`
        );
    }
    return (
        <ContentWrapper>
            <Grid>
                {tiles.length > 0 ? (
                    <>
                        <DescriptionCell pb={22} gridColumn={['1 / -1', null, null, '8 / 11', null, '8 / 12']}>
                            <Heading>
                                <Title variant="large">
                                    <FormattedMessage defaultMessage="Bijzonder aan deze accommodatie" />
                                </Title>
                            </Heading>
                            <TextClamp text={description} />
                        </DescriptionCell>
                        <TilesCell gridColumn={['1 / -1', null, null, '1 / 7']}>
                            <AttributeGrid>{tiles}</AttributeGrid>
                        </TilesCell>
                    </>
                ) : (
                    <Content>
                        <Heading>
                            <Title variant="large">
                                <FormattedMessage defaultMessage="Bijzonder aan deze accommodatie" />
                            </Title>
                        </Heading>
                        <TextClamp maxLength={200} text={description} variant="regular" />
                    </Content>
                )}
            </Grid>
        </ContentWrapper>
    );
};

const Content = styled.div`
    grid-column: 1 / -1;
    max-width: 80.8rem;

    > * + * {
        margin-top: ${({ theme }) => theme.spacing['40_Standard']};
        @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
            margin-top: ${({ theme }) => theme.spacing['50_Semi']};
        }
    }
`;

const DescriptionCell = styled(Cell)`
    order: 1;
    align-self: center;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        order: 2;
    }
`;

const TilesCell = styled(Cell)`
    order: 2;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        order: 1;
    }
`;

export default Extras;
