import { Box } from '@rebass/grid';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import ContentWrapper from './ContentWrapper';
import Page from './Page';
import Passport from './Passport';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import UspsModal from './UspsModal';

const UspsContent = () => {
    const {
        brandConfig: { showUsps, passport, usps },
    } = useTenantContext();
    const [visibleIndex, setVisibleIndex] = useState(0);

    const modal = useSesame();

    if (!showUsps && !passport) {
        return null;
    }

    return (
        <Page>
            <UspsModal visibleIndex={visibleIndex} onClose={modal.onClose} open={modal.open} />
            <StyledContentWrapper className="ups-content-container">
                {passport && <Passport />}
                <Grid length={usps?.length} my={[4, 8]} py={[4, 8]} px={[7, 0]}>
                    {usps?.map(({ title }, i) => (
                        <USP
                            onClick={() => {
                                setVisibleIndex(i);
                                modal.onOpen();
                            }}
                            key={i}
                        >
                            {title}
                        </USP>
                    ))}
                </Grid>
            </StyledContentWrapper>
        </Page>
    );
};

export default UspsContent;

const Grid = styled(Box)<{ length?: number }>`
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3.6rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        grid-template-columns: ${({ length }) => `repeat(${length}, 1fr)`};
    }
`;

const StyledContentWrapper = styled(ContentWrapper)`
    > div {
        border-top: 1px solid ${({ theme }) => theme.colors.neutral['20']};
        margin-top: ${({ theme }) => theme.spacing['60_Large']};
        padding-top: ${({ theme }) => theme.spacing['60_Large']};

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-top: ${({ theme }) => theme.spacing['80_XXLarge']};
            padding-top: ${({ theme }) => theme.spacing['80_XXLarge']};
        }
    }
`;

const USP = styled.button`
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary['50']};
    justify-content: flex-start;
    display: flex;
    margin-top: ${({ theme }) => theme.spacing['30_Small']};
    ${({ theme }) => getFontCss(theme.fontSizes.body.regular)};
    text-align: left;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: column;
        text-align: center;
    }

    p {
        font-weight: 500;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            min-height: 4.8rem;
        }
    }

    &:last-child {
        grid-column: span 2;

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            grid-column: auto;
        }
    }

    svg {
        transition: transform 0.2s ease-in-out;
        max-width: 2rem;
        margin-right: 2.1rem;

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-right: 0;
        }

        path,
        circle {
            stroke-width: 3;
        }
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
            max-width: unset;

            path,
            circle {
                stroke-width: 2;
            }
        }
    }

    :hover {
        svg {
            transform: scale(1.1);
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        grid-column: auto;
    }
`;
