import React, { FC } from 'react';
import Modal, { ModalProps } from '@oberoninternal/travelbase-ds/components/layout/Modal';
import styled from 'styled-components';

const MediaModal: FC<React.PropsWithChildren<ModalProps>> = ({ children, open, onClose }) => (
    <StyledModal variant="large" open={open} onClose={onClose}>
        {children}
    </StyledModal>
);

const StyledModal = styled(Modal)`
    height: 100vh;

    > [data-reach-dialog-content] {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 8rem 6.8rem;

        @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            height: 100%;
            padding: 8rem 2rem 2rem;
        }
    }
`;

export default MediaModal;
