import { ReactNode } from 'react';
import accommodationImageCategoryTexts from '../constants/accommodationImageCategoryTexts';
import { AccommodationImageCategoryEnum, ImagesFragment, TransformFragment } from '../generated/graphql';

type Image = {
    jpeg: Partial<TransformFragment>;
    webp: Partial<TransformFragment>;
};

export type ImageCategories = {
    translatedCategoryName: ReactNode;
    images: Image[];
}[];

export type CategorizedImages = Array<{ category: AccommodationImageCategoryEnum; images: ImagesFragment[] }>;

export const accomodationImagesOrder: AccommodationImageCategoryEnum[] = [
    AccommodationImageCategoryEnum.Front,
    AccommodationImageCategoryEnum.LivingRoom,
    AccommodationImageCategoryEnum.Kitchen,
    AccommodationImageCategoryEnum.Bedroom,
    AccommodationImageCategoryEnum.Entrance,
    AccommodationImageCategoryEnum.Bathroom,
    AccommodationImageCategoryEnum.Outdoor,
    AccommodationImageCategoryEnum.View,
    AccommodationImageCategoryEnum.Facilities,
    AccommodationImageCategoryEnum.Surroundings,
    AccommodationImageCategoryEnum.FloorPlan,
    AccommodationImageCategoryEnum.Other,
];

export const groupImagesByCategory = (images: ImagesFragment[]) => {
    const groupedImages: CategorizedImages = [];

    accomodationImagesOrder.forEach(category => {
        const filteredImages = images.filter(image => image.category === category);

        if (filteredImages.length > 0) {
            groupedImages.push({ category, images: filteredImages });
        }
    });

    return groupedImages;
};

export const groupImageByCategoryClean = (images: ImagesFragment[], full = false) => {
    const groupedImages: ImageCategories = [];

    accomodationImagesOrder.forEach(category => {
        const filteredImages = images
            .filter((image): image is Required<typeof image> => image.category === category)
            .map(
                (image): Image => ({
                    jpeg: {
                        ratio: image[full ? 'jpegGallery' : 'jpegTile']?.ratio,
                        src: image[full ? 'jpegGallery' : 'jpegTile']?.src,
                        srcSet: image[full ? 'jpegGallery' : 'jpegTile']?.srcSet,
                        placeholder: image[full ? 'webpGallery' : 'webpTile']?.placeholder,
                    },
                    webp: {
                        ratio: image[full ? 'webpGallery' : 'webpTile']?.ratio,
                        src: image[full ? 'webpGallery' : 'webpTile']?.src,
                        srcSet: image[full ? 'webpGallery' : 'webpTile']?.srcSet,
                        placeholder: image[full ? 'webpGallery' : 'webpTile']?.placeholder,
                    },
                })
            );

        if (filteredImages.length > 0) {
            groupedImages.push({
                translatedCategoryName: accommodationImageCategoryTexts[category],
                images: filteredImages,
            });
        }
    });

    return groupedImages;
};
