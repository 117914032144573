import React, { FC } from 'react';
import { ModalProps } from '@oberoninternal/travelbase-ds/components/layout/Modal';
import MediaModal from './MediaModal';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player'));

interface Props extends ModalProps {
    src?: string | null;
}

const VideoModal: FC<React.PropsWithChildren<Props>> = ({ open, onClose, src }) => {
    const srcUrl = src ?? '';
    return (
        <MediaModal open={open} onClose={onClose}>
            <PlayerWrapper>
                <StyledReactPlayer url={srcUrl} width="100%" height="100%" controls />
            </PlayerWrapper>
        </MediaModal>
    );
};

export default VideoModal;

const PlayerWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    padding-top: 56.25%;
`;

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;
