import { gql, MutationFunctionOptions, QueryHookOptions, useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '../apollo';
import { TRAVELBASE_AFF, TRAVELBASE_AFF_REFERENCE } from '../constants/affiliate';
import { ACCESS_TOKEN } from '../constants/storage';
import {
    BookingCartItemInput,
    BookingCartItemSurchargeInput,
    CartBookingFragment,
    CartOrderItemFragment,
    CartOrderItem_Booking_Fragment,
    CartOrderItem_Ticket_Fragment,
    CartOrderItem_UpsellPurchase_Fragment,
    CartStatusEnum,
    CartTicketFragment,
    CartUpsellFragment,
    GetCartDocument,
    GetCartQuery,
    GetCartQueryVariables,
    SetCartDocument,
    SetCartMutation,
    SetCartMutationVariables,
    TicketCartItemInput,
    TicketRateLineInput,
    UpdateCartInput,
    UpsellPurchaseCartItemInput,
} from '../generated/graphql';
import { logout, refresh } from '../utils/auth';
import { clearCartId, getCartId, setCartId } from '../utils/cart';
import { truthy } from '../utils/fp';
import getApolloErrorType from '../utils/getApolloErrorType';
import getStorageWithExpiry from '../utils/getStorageWithExpiry';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const query = gql`
    query GetCart($id: ID!) {
        cart(id: $id) {
            ...GetCart
        }
    }

    query CartStatus($cartId: ID!) {
        cart(id: $cartId) {
            id
            status
        }
    }

    mutation SetCart($input: UpdateCartInput!) {
        updateCart(input: $input) {
            cart {
                ...GetCart
            }
        }
    }

    fragment GetCart on Cart {
        id
        status
        availableUpsellOffers {
            id
            name
            unitPrice
            maxAmount
            description
        }
        order {
            orderItems {
                ...CartOrderItem
            }
        }
    }

    fragment CartOrderItem on OrderItem {
        priceLines {
            label
            quantity
            totalPrice
            unitPrice
            category
        }

        totalPrice
        paymentPrice
        errors
        ...CartTicket
        ...CartBooking
        ...CartUpsell
    }

    fragment CartUpsell on UpsellPurchase {
        priceLines {
            label
            quantity
            totalPrice
            unitPrice
            category
        }
        offer {
            id
        }
        totalPrice
        paymentPrice
        amount
    }

    fragment CartBooking on Booking {
        status
        amountAdults
        amountPets
        amountChildren
        amountBabies
        amountYouths
        arrivalDate
        departureDate
        hasCancellationInsurance
        hasContentsInsurance
        handleDepositPayment
        deposit
        requiresApproval
        paymentPrice
        rentalUnit {
            id
        }
        special {
            id
        }
        optionalSurcharges {
            rentalUnitSurchargeId
            amount
        }
    }

    fragment CartTicket on Ticket {
        ticketStatus: status
        startDateTime
        activity {
            id
            slug
        }
        timeslot {
            ...ActivityPlannerTimeslot
        }

        rateLines {
            amount
            rateId
            totalPrice
            unitPrice
        }
    }
`;

export const getTicketInput = (ticket: CartTicketFragment): TicketCartItemInput | null => {
    if (!ticket.timeslot || !ticket.rateLines) {
        return null;
    }
    return {
        timeslotId: ticket.timeslot.id,
        rateLines: ticket.rateLines.map(
            (rateLine): TicketRateLineInput => ({
                amount: rateLine.amount,
                rateId: rateLine.rateId!,
            })
        ),
    };
};

export const getOptionalSurchargesInput = (optionalSurcharges: BookingCartItemSurchargeInput[]) =>
    optionalSurcharges.map(surcharge => ({
        amount: surcharge.amount,
        rentalUnitSurchargeId: surcharge.rentalUnitSurchargeId,
    }));

export const getBookingInput = (booking: CartBookingFragment): BookingCartItemInput => ({
    amountAdults: booking.amountAdults,
    amountChildren: booking.amountChildren,
    amountBabies: booking.amountBabies,
    amountPets: booking.amountPets,
    amountYouths: booking.amountYouths,
    arrivalDate: booking.arrivalDate,
    departureDate: booking.departureDate,
    cancellationInsurance: booking.hasCancellationInsurance,
    contentsInsurance: booking.hasContentsInsurance,
    optionalSurcharges: getOptionalSurchargesInput(booking.optionalSurcharges),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rentalUnitId: booking.rentalUnit?.id!,
    specialId: booking.special?.id,
});

export const getUpsellInput = (upsell: CartUpsellFragment): UpsellPurchaseCartItemInput | null => {
    if (!upsell.amount || !upsell.offer?.id) {
        return null;
    }
    return {
        amount: upsell.amount,
        upsellOfferId: upsell.offer?.id,
    };
};

export type OrderItemShape = { __typename?: 'Ticket' | 'Booking' | 'UpsellPurchase' };
export type CartShape = {
    __typename?: 'Cart';
    status: CartStatusEnum;
    order: { __typename?: 'Order'; orderItems: Array<OrderItemShape> };
};

export const cartItemIsBooking = (item: OrderItemShape): item is CartOrderItem_Booking_Fragment =>
    item.__typename === 'Booking';
export const cartItemIsTicket = (item: OrderItemShape): item is CartOrderItem_Ticket_Fragment =>
    item.__typename === 'Ticket';
export const cartItemIsUpsell = (item: OrderItemShape): item is CartOrderItem_UpsellPurchase_Fragment =>
    item.__typename === 'UpsellPurchase';

export const getTicketsInputFromCart = <T extends CartShape>(cart?: T | null) => {
    if (!cart || cart?.status === CartStatusEnum.Closed) {
        return [];
    }
    return cart.order.orderItems.filter(cartItemIsTicket).map(getTicketInput).filter(truthy);
};

export const getBookingsInputFromCart = <T extends CartShape>(cart?: T | null) => {
    if (!cart || cart?.status === CartStatusEnum.Closed) {
        return [];
    }
    return cart.order.orderItems.filter(cartItemIsBooking).map(getBookingInput);
};

export const getUpsellInputFromCart = <T extends CartShape>(cart?: T | null) => {
    if (!cart || cart?.status === CartStatusEnum.Closed) {
        return [];
    }
    return cart.order.orderItems.filter(cartItemIsUpsell).map(getUpsellInput).filter(truthy);
};

export type RemoveOrderItem = {
    (id: string, type: NonNullable<CartOrderItemFragment['__typename']>): Promise<void>;
    (id: null): Promise<void>;
};

export const useCart = <Query extends GetCartQuery>(
    document = GetCartDocument,
    queryOptions?: Omit<QueryHookOptions<Query, GetCartQueryVariables>, 'variables' | 'ssr'>
) => {
    const cartQuery = useQuery<Query, GetCartQueryVariables>(document, {
        ssr: false,
        variables: { id: getCartId()! },
        errorPolicy: 'ignore',
        skip: !getCartId(),
        ...queryOptions,
    });

    useEffect(() => {
        const errorType = getApolloErrorType(cartQuery.error);
        switch (errorType) {
            // if we get a server error we'll remove the cartId from localStorage
            case '500':
                clearCartId();
                break;
            case '401':
                logout();
                break;
            default:
                break;
        }
    }, [cartQuery.error]);

    const bookings = cartQuery.data?.cart?.order.orderItems.filter(cartItemIsBooking) ?? [];
    const tickets = cartQuery.data?.cart?.order.orderItems.filter(cartItemIsTicket) ?? [];
    const upsell = cartQuery.data?.cart?.order.orderItems.filter(cartItemIsUpsell) ?? [];

    return { tickets, bookings, upsell, cartQuery };
};

export type SetCartOptions<Mutation> = Partial<UpdateCartInput> & {
    options?: Omit<MutationFunctionOptions<Mutation, SetCartMutationVariables>, 'variables'>;
};

export const useSetCart = <Mutation extends SetCartMutation>(
    mutationDoc = SetCartDocument,
    queryDoc = GetCartDocument
) => {
    const [mutate] = useMutation<Mutation, SetCartMutationVariables>(mutationDoc);
    const [loading, setLoading] = useState(false);
    const client = useApolloClient();

    const getCart = useCallback(() => {
        const cartId = getCartId();
        if (!cartId) {
            return undefined;
        }
        return client.query<GetCartQuery, GetCartQueryVariables>({
            query: queryDoc,
            variables: { id: cartId },
            fetchPolicy: 'network-only',
        });
    }, [client, queryDoc]);

    const setCart = useCallback(
        async (opts: SetCartOptions<Mutation>) => {
            setLoading(true);

            // first check if we're logged in, and refresh the token just in case
            if (getStorageWithExpiry('local')?.getItem(ACCESS_TOKEN)) {
                const success = await refresh();
                if (!success) {
                    logout();
                }
            }

            // then check if we already have a cartId we can use
            let reusedCartId: null | string = null;
            const cartQuery = await getCart();
            const currentCart = cartQuery?.data.cart;

            // only reuse the cart if it hasn't been closed yet
            reusedCartId = currentCart?.id && currentCart.status !== CartStatusEnum.Closed ? currentCart.id : null;

            const currentTickets = getTicketsInputFromCart(currentCart);
            const currentBookings = getBookingsInputFromCart(currentCart);
            const tickets = opts.tickets ?? currentTickets;
            const bookings = opts.bookings ?? currentBookings;
            const affiliateHandle = Cookies.get(TRAVELBASE_AFF);
            const affiliateReference = Cookies.get(TRAVELBASE_AFF_REFERENCE);
            const result = await mutate({
                ...opts.options,
                variables: {
                    input: {
                        affiliateInfo: affiliateHandle
                            ? {
                                  reference: affiliateReference,
                                  handle: affiliateHandle,
                              }
                            : undefined,
                        tickets,
                        bookings,
                        agreedToTerms: opts.agreedToTerms,
                        cartId: reusedCartId,
                        customerInfo: opts.customerInfo,
                    },
                },
            });

            setLoading(false);
            if (result.data?.updateCart.cart?.id) {
                setCartId(result.data.updateCart.cart.id);
            }

            return result;
        },
        [getCart, mutate]
    );

    const removeOrderItem: RemoveOrderItem = useCallback(
        async (id: string | null, type?: NonNullable<CartOrderItemFragment['__typename']>) => {
            const cartQuery = await getCart();
            if (!cartQuery?.data.cart) {
                return;
            }
            await setCart({
                tickets:
                    type === 'Ticket'
                        ? getTicketsInputFromCart(cartQuery.data?.cart).filter(ticket => ticket.timeslotId !== id) ?? []
                        : undefined,
                bookings:
                    type === 'Booking'
                        ? getBookingsInputFromCart(cartQuery.data?.cart).filter(
                              booking => booking.rentalUnitId !== id
                          ) ?? []
                        : undefined,
            });
        },
        [getCart, setCart]
    );

    return { setCart, removeOrderItem, loading };
};

export type CartHook = ReturnType<typeof useCart>;
