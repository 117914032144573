import styled from 'styled-components';
import LabelButton from '@oberoninternal/travelbase-ds/components/action/LabelButton';

const MediaButton = styled(LabelButton)`
    height: 4.8rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    font-size: 1.6rem;
    .button-icon {
        font-size: 2.4rem;
        margin-right: 0.8rem;
    }
    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        height: 32px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0.8rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.3rem;
        .button-icon {
            font-size: 1.6rem;
            margin-right: 0.4rem;
        }
    }
`;

export default MediaButton;
