import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { rentalUnitTypes } from '../constants/filterTypes';
import getUnitTypeConsts from '../constants/getUnitTypeConsts';
import { useTenantContext } from '../context/TenantContext';
import { Locale } from '../entities/Locale';
import { UnitDetailsQuery } from '../generated/graphql';
import BreadCrumbs from './Breadcrumbs';

interface Props {
    data: NonNullable<UnitDetailsQuery['rentalUnit']>;
}

const UnitBreadCrumbs = ({ data: { type, name } }: Props) => {
    const intl = useIntl();
    const router = useRouter();
    const locale = router.locale as Locale;
    const hasSearched = !!router.query.searched;
    const { brandConfig } = useTenantContext();
    const homepageUrl = brandConfig.homepageUrl?.[locale];

    const relevantAccType =
        brandConfig.accommodationTypes.find(option => option.value.includes(rentalUnitTypes[type]))?.value ?? 'all';

    const searchPathname = brandConfig.searchUri?.[router.locale as Locale] ?? `/search`;
    const searchResultsHref = {
        pathname: searchPathname,
        search: router.query.searched as string,
    };

    return (
        <BreadCrumbs>
            {hasSearched && <BreadCrumbs.PrevLink href={searchResultsHref} />}
            <BreadCrumbs.Crumb
                className="gt-s"
                href={
                    homepageUrl ?? {
                        pathname: `/`,
                    }
                }
            >
                Home
            </BreadCrumbs.Crumb>
            {hasSearched && (
                <BreadCrumbs.Crumb className="gt-s" href={searchResultsHref}>
                    Zoekresultaten
                </BreadCrumbs.Crumb>
            )}
            {!hasSearched && (
                <BreadCrumbs.Crumb
                    className="gt-s"
                    href={{
                        pathname: searchPathname,
                        query: {
                            accommodationType: relevantAccType,
                        },
                    }}
                >
                    {relevantAccType ? (
                        getUnitTypeConsts(intl)[type].translation
                    ) : (
                        <FormattedMessage defaultMessage="Alle accommodaties" />
                    )}
                </BreadCrumbs.Crumb>
            )}
            <BreadCrumbs.Crumb active className="gt-s">
                {name}
            </BreadCrumbs.Crumb>
        </BreadCrumbs>
    );
};

export default UnitBreadCrumbs;
