import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccommodationImageCategoryEnum } from '../generated/graphql';

const accommodationImageCategoryTexts: Record<AccommodationImageCategoryEnum, ReactNode> = {
    FRONT: <FormattedMessage defaultMessage="Vooraanzicht" />,
    LIVING_ROOM: <FormattedMessage defaultMessage="Woonkamer" />,
    KITCHEN: <FormattedMessage defaultMessage="Keuken" />,
    BEDROOM: <FormattedMessage defaultMessage="Slapen" />,
    ENTRANCE: <FormattedMessage defaultMessage="Entree/receptie" />,
    BATHROOM: <FormattedMessage defaultMessage="Sanitair" />,
    OUTDOOR: <FormattedMessage defaultMessage="Buitenruimte" />,
    VIEW: <FormattedMessage defaultMessage="Uitzicht" />,
    FACILITIES: <FormattedMessage defaultMessage="Faciliteiten" />,
    SURROUNDINGS: <FormattedMessage defaultMessage="Omgeving" />,
    FLOOR_PLAN: <FormattedMessage defaultMessage="Plattegrond" />,
    OTHER: <FormattedMessage defaultMessage="Overig" />,
};

export default accommodationImageCategoryTexts;
