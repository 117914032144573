import {
    AggregateRating,
    LocationFeatureSpecification,
    LodgingBusiness,
    Rating,
    Review,
    WithContext,
} from 'schema-dts';
import { useTenantContext } from '../context/TenantContext';
import { UnitDetailsQuery } from '../generated/graphql';

const RATING_BOUNDS: Pick<Rating, 'worstRating' | 'bestRating'> = {
    worstRating: 1,
    bestRating: 10,
};

const useUnitSchema = (rentalUnit?: UnitDetailsQuery['rentalUnit']): WithContext<LodgingBusiness> => {
    const { supportedLanguages } = useTenantContext();
    const hasReviews = rentalUnit?.reviews && rentalUnit?.reviewStats && rentalUnit?.reviews?.length > 0;
    const aggregateRating: AggregateRating | undefined = hasReviews
        ? {
              '@type': 'AggregateRating',
              ratingValue: rentalUnit?.reviewStats?.average,
              reviewCount: rentalUnit?.reviewStats?.count,
              ...RATING_BOUNDS,
          }
        : undefined;

    const review: Review[] | undefined = hasReviews
        ? rentalUnit?.reviews.map(
              ({ date, title, comment, average }): Review => ({
                  '@type': 'UserReview',
                  datePublished: date,
                  description: comment,
                  name: title,
                  author: {
                      '@type': 'Person',
                      name: 'unknown',
                  },
                  reviewRating: {
                      '@type': 'Rating',
                      ratingValue: average,
                      ...RATING_BOUNDS,
                  },
              })
          )
        : undefined;

    return {
        '@context': 'https://schema.org',
        // @ts-ignore
        '@type': 'LodgingBusiness',
        name: rentalUnit?.name,
        description: rentalUnit?.descriptionLayout,
        slogan: rentalUnit?.tagline,
        image: [
            rentalUnit?.jpegThumbnail?.transform?.src ?? '',
            ...(rentalUnit?.images.map(({ jpegTile }) => jpegTile?.src ?? '') ?? []),
        ].filter(img => !!img),

        address: {
            '@type': 'PostalAddress',
            addressLocality: rentalUnit?.accommodation.place,

            // hardcoded, but who cares?
            addressRegion: 'Friesland',
            addressCountry: {
                '@type': 'Country',
                name: 'NLD',
            },
        },

        containedInPlace: {
            '@type': 'Accommodation',
            numberOfBedrooms: rentalUnit?.amountBedrooms ?? 0,
            petsAllowed: (rentalUnit?.petsAllowed ?? 0) > 0,
            numberOfFullBathrooms: rentalUnit?.amountBathrooms ?? 0,
            floorSize: rentalUnit?.livingArea
                ? {
                      '@type': 'QuantitativeValue',
                      unitCode: 'MTK',
                      value: rentalUnit.livingArea,
                  }
                : undefined,
        },

        amenityFeature: (rentalUnit?.attributeCategories ?? []).flatMap(group =>
            group.attributes.map(
                (attr): LocationFeatureSpecification => ({
                    '@type': 'LocationFeatureSpecification',
                    name: attr.name,
                    value: true,
                })
            )
        ),
        availableLanguage: supportedLanguages,

        checkinTime: `${rentalUnit?.accommodation.checkInStartTime} - ${rentalUnit?.accommodation.checkInEndTime}`,
        checkoutTime: rentalUnit?.accommodation.checkOutTime,

        geo: {
            '@type': 'GeoCoordinates',
            latitude: rentalUnit?.accommodation.coordinates.lat,
            longitude: rentalUnit?.accommodation.coordinates.lon,
        },
        aggregateRating,
        review,
    };
};

export default useUnitSchema;
