import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import React, { FC } from 'react';
import { PlannerProps } from './TripPlanner';
import TripPlannerModal from './TripPlannerModal';

interface Props extends Pick<Sesame, 'onClose' | 'open'>, PlannerProps {
    name?: string;
    id: string;
    onBookingReservation: () => void;
    reserving: boolean;
    maxOccupancy: number;
    petsAllowed: number;
}

const UnitDetailsPlannerModal: FC<React.PropsWithChildren<Props>> = ({
    onClose,
    open,
    name,
    id,
    reserving,
    maxOccupancy,
    petsAllowed,
    onBookingReservation,
    ...props
}) => (
    <TripPlannerModal
        maxOccupancy={maxOccupancy}
        petsAllowed={petsAllowed}
        id={id}
        name={name}
        open={open}
        onClose={onClose}
        onBookingReservation={onBookingReservation}
        {...props}
    />
);

export default UnitDetailsPlannerModal;
