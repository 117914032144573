import React, { FC, ReactNode } from 'react';
import { ImagesFragment } from '../generated/graphql';
import { groupImagesByCategory } from '../utils/groupImagesByCategory';
import Carousel from './designsystem/Carousel';
import PhotoCategory from './PhotoCategory';

interface Props {
    data: ImagesFragment[];
    onCategoryClick: (categoryIndex: number) => void;
    title?: ReactNode;
}

const PhotoCategoryGallery: FC<React.PropsWithChildren<Props>> = ({ data, onCategoryClick, title }) => {
    const groupedImages = groupImagesByCategory(data);
    const rooms = groupedImages.map(({ category, images }, index) => {
        const image = images[0];
        if (!image?.jpegTile || !image?.webpTile) {
            return null;
        }
        return (
            <PhotoCategory
                key={category}
                category={category}
                jpeg={image.jpegTile}
                webp={image.webpTile}
                count={images?.length}
                onClick={() => {
                    onCategoryClick(index);
                }}
            />
        );
    });

    return (
        <Carousel title={title} continuous>
            {rooms}
        </Carousel>
    );
};

export default PhotoCategoryGallery;
