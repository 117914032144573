import React, { FC } from 'react';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import styled from 'styled-components';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { FormattedMessage, useIntl } from 'react-intl';
import Cell from './designsystem/Cell';
import Heading from './designsystem/Heading';
import { Locale } from '../entities/Locale';
import { useTenantContext } from '../context/TenantContext';
import Image from 'next/image';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import Grid from './designsystem/Grid';
import { LocationDescriptionByBrand } from '../constants/brandConfig';

interface Props {
    hasReviews: boolean;
    brand?: string;
}

const UnitLocationDescription: FC<Props> = ({ hasReviews, brand }) => {
    const { brandConfig } = useTenantContext();
    const { locale } = useIntl();
    const deviceSize = useDeviceSize();

    const nativeLocationDescription =
        brand && brandConfig.locationDescription?.useNativeLocationDescription
            ? brandConfig.locationDescription.locationDescriptionByBrand?.[brand as keyof LocationDescriptionByBrand]
            : brandConfig.locationDescription;

    const { imgAlt, tips, imgSrc, description } = nativeLocationDescription ?? {};

    if (!imgAlt || !tips || !imgSrc || !description) {
        return null;
    }

    const tipsImage = (
        <ImageContainer className={!hasReviews ? 'tips-image-container' : ''}>
            <Image objectFit="cover" layout="fill" src={imgSrc} alt={imgAlt[locale as Locale]} loading="lazy" />
        </ImageContainer>
    );

    const gridDisplay = deviceSize !== 'mobile' && ((hasReviews && deviceSize === 'tablet') || !hasReviews);

    return (
        <Cell
            pb={22}
            pt={[5, 0]}
            gridColumn={[
                '1 / -1',
                null,
                null,
                `${hasReviews ? '7 / -1' : '1 / -1'}`,
                null,
                `${hasReviews ? '9 / -1' : '1 / -1'}`,
            ]}
        >
            <Container>
                <TipsContainer gridDisplay={gridDisplay} hasReviews={hasReviews}>
                    {gridDisplay && tipsImage && (
                        <Cell gridColumn={[null, null, '1 / 3', '1 / 5', '1 / 5', '1 / 5']}>{tipsImage}</Cell>
                    )}

                    <Cell
                        alignSelf={hasReviews ? 'initial' : 'center'}
                        width={1}
                        gridColumn={['1 / -1', '1 / -1', '3 / -1', `${hasReviews ? '1 / 7' : '6 / -1'}`]}
                    >
                        <Heading>
                            <Title variant="large">
                                <FormattedMessage defaultMessage="Tips voor uw verblijf" />
                            </Title>
                        </Heading>

                        {!gridDisplay && tipsImage}

                        <Body dangerouslySetInnerHTML={{ __html: description[locale as Locale] }} />
                        <Tips>
                            <FormattedMessage defaultMessage="Wij geven u graag 5 'geheime tips':" />
                            <List>
                                {tips[locale as Locale].map((tip, i) => (
                                    <ListItem key={tip}>
                                        <Body>
                                            <TipCount>
                                                <FormattedMessage defaultMessage="Tip" /> {i + 1}
                                            </TipCount>{' '}
                                            {/* eslint-disable-next-line react/no-danger */}
                                            <span dangerouslySetInnerHTML={{ __html: tip }} />
                                        </Body>
                                    </ListItem>
                                ))}
                            </List>
                        </Tips>
                    </Cell>
                </TipsContainer>
            </Container>
        </Cell>
    );
};

const Container = styled.div`
    height: 100%;
`;

const TipsContainer = styled(Grid)<{ gridDisplay: boolean; hasReviews: boolean }>`
    display: ${({ gridDisplay }) => (gridDisplay ? 'grid' : 'flex')};
    position: relative;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueriesValues.xs}px) {
        padding-top: ${({ hasReviews }) => (hasReviews ? '5rem' : '0')};
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueriesValues.m}px) {
        padding-top: 0;
    }
`;
const ImageContainer = styled.div`
    ::after {
        padding-top: calc(9 / 16 * 100%);
        content: '';
        display: block;
    }
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing['50_Semi']};

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        max-width: '100%';
    }
`;

const Tips = styled.div`
    margin-top: 2.7rem;
`;

const List = styled.div`
    list-style: none;
    margin-top: 2.7rem;
`;

const TipCount = styled.span`
    font-weight: bold;
`;

const ListItem = styled.div``;

export default UnitLocationDescription;
