import React, { FC } from 'react';
import { gql } from '@apollo/client';
import { UnitOwnerFragment } from '../generated/graphql';
import styled from 'styled-components';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage } from 'react-intl';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Cell from './designsystem/Cell';
import Grid from './designsystem/Grid';
import createImgProps from '../utils/createImgProps';
import Img from './Img';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';

export const unitOwnerFragment = gql`
    fragment UnitOwner on Accommodation {
        jpegOwner: ownerImage {
            transform(config: AVATAR, format: JPEG) {
                ...Transform
            }
        }
        webpOwner: ownerImage {
            transform(config: AVATAR, format: WEBP) {
                ...Transform
            }
        }
        ownerIntroduction
        ownerName
        ownerTips
    }
`;

interface Props {
    data: UnitOwnerFragment;
    hasReviews: boolean;
}

const UnitOwner: FC<React.PropsWithChildren<Props>> = ({
    data: { ownerTips, ownerName, ownerIntroduction, jpegOwner, webpOwner },
    hasReviews,
}) => {
    const deviceSize = useDeviceSize();
    const imgProps = jpegOwner && webpOwner && createImgProps(jpegOwner, webpOwner);

    const owner = imgProps && (
        <OwnerImgWrapper hasReviews={hasReviews}>
            <div>
                <Img
                    layout="fill"
                    loading="lazy"
                    {...{
                        ...imgProps,
                        fallback: { ...imgProps.fallback, alt: ownerName ?? '' },
                    }}
                />
            </div>
        </OwnerImgWrapper>
    );

    const gridDisplay = deviceSize !== 'mobile' && ((hasReviews && deviceSize === 'tablet') || !hasReviews);

    return (
        <Cell
            pb={22}
            pt={[5, 0]}
            gridColumn={[
                '1 / -1',
                null,
                null,
                `${hasReviews ? '7 / -1' : '1 / -1'}`,
                null,
                `${hasReviews ? '7 / -1' : '1 / -1'}`,
            ]}
        >
            <Container>
                <OwnerContainer gridDisplay={gridDisplay} hasReviews={hasReviews}>
                    {gridDisplay && owner && (
                        <Cell gridColumn={[null, null, '1 / 3', '2 / 5', '2 / 5', '2 / 5']}>{owner}</Cell>
                    )}

                    <Cell width={1} gridColumn={['1 / -1', '1 / -1', '3 / -1', `${hasReviews ? '1 / 7' : '6 / -1'}`]}>
                        <OwnerMain>
                            <div>
                                <StyledTitle hasReviews={hasReviews} variant="large">
                                    <FormattedMessage defaultMessage="Verhuurd door" />{' '}
                                </StyledTitle>
                                <StyledOwnerName hasReviews={hasReviews} variant="large">
                                    {ownerName}
                                </StyledOwnerName>
                            </div>
                            {!gridDisplay && owner}
                        </OwnerMain>

                        {ownerIntroduction && (
                            <OwnerIntroduction>
                                <OwnerIntroductionTitle>
                                    <FormattedMessage defaultMessage="Even voorstellen" />
                                </OwnerIntroductionTitle>
                                <Body>{ownerIntroduction}</Body>
                            </OwnerIntroduction>
                        )}

                        {ownerTips && (
                            <OwnerTips>
                                <OwnerTipsTitle>
                                    <FormattedMessage defaultMessage="Tips van de eigenaar" />
                                </OwnerTipsTitle>
                                <Body>{ownerTips}</Body>
                            </OwnerTips>
                        )}
                    </Cell>
                </OwnerContainer>
            </Container>
        </Cell>
    );
};

const OwnerIntroduction = styled.div`
    margin-top: ${({ theme }) => theme.spacing['50_Semi']};
`;

const OwnerIntroductionTitle = styled(Title)`
    margin-bottom: 1.2rem;
    font-weight: 500;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-bottom: 2rem;
    }
`;

const OwnerTips = styled.div`
    margin-top: ${({ theme }) => theme.spacing['70_XLarge']};

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-top: ${({ theme }) => theme.spacing['50_Semi']};
    }
`;

const OwnerTipsTitle = styled(OwnerIntroductionTitle)``;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const OwnerContainer = styled(Grid)<{ gridDisplay: boolean; hasReviews: boolean }>`
    display: ${({ gridDisplay }) => (gridDisplay ? 'grid' : 'flex')};
    align-items: center;
    position: relative;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueriesValues.xs}px) {
        padding-top: ${({ hasReviews }) => (hasReviews ? '5rem' : '0')};
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueriesValues.m}px) {
        padding-top: 0;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueriesValues.l}px) {
        padding-top: 0;
        max-width: ${({ hasReviews }) => (hasReviews ? '56.8rem' : 'unset')};
        margin-left: auto;
    }
`;

const OwnerMain = styled.div`
    display: flex;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        align-items: flex-start;
    }
    justify-content: space-between;
    width: 100%;
`;

const StyledTitle = styled(Title)<{ hasReviews: boolean }>`
    font-weight: 300;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: inline;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: ${({ hasReviews }) => (hasReviews ? 'block' : 'inline')};
    }
`;

const StyledOwnerName = styled(Title)<{ hasReviews: boolean }>`
    margin: 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: inline;
        margin-top: ${({ theme }) => theme.spacing['30_Small']};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: ${({ hasReviews }) => (hasReviews ? 'block' : 'inline')};
    }
`;

const OwnerImgWrapper = styled.div<{ hasReviews: boolean }>`
    > div {
        position: relative;
        padding-bottom: 100%;
        width: 100%;
        > div {
            border-radius: 50%;
            overflow: hidden;
        }
        img {
            object-fit: cover;
        }
    }
    padding-left: 0.8rem;
    width: 100%;
    max-width: ${({ hasReviews }) => (hasReviews ? '5rem' : '7rem')};
    right: 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        max-width: 8rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        max-width: 20rem;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        max-width: ${({ hasReviews }) => (hasReviews ? '10.4rem' : '35rem')};
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        > div {
            > figure {
                margin-top: ${({ hasReviews }) => (hasReviews ? '-1.6rem' : 0)};
            }
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        max-width: ${({ hasReviews }) => (hasReviews ? '10.4rem' : 'unset')};
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xxl}) {
        max-width: ${({ hasReviews }) => (hasReviews ? '10.4rem' : 'unset')};
    }
`;

export default UnitOwner;
