import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { gql } from '@apollo/client';
import React, { FC, memo, useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { LocationFragment, PointOfInterest } from '../generated/graphql';
import ContentWrapper from './ContentWrapper';
import Cell from './designsystem/Cell';
import GradientBackground from './designsystem/GradientBackground';
import Grid from './designsystem/Grid';
import Heading from './designsystem/Heading';
import TextClamp from './designsystem/TextClamp';
import LocationMap from './LocationMap';

export const fragment = gql`
    fragment Location on Accommodation {
        descriptionLocation
        pointsOfInterest {
            name
            distance
        }
        coordinates {
            lat
            lon
        }
    }
`;

interface Props {
    data: LocationFragment;
}

const Location: FC<React.PropsWithChildren<Props>> = props => {
    const { data } = props;
    const {
        pointsOfInterest,
        coordinates: { lat, lon },
        descriptionLocation,
    } = data;

    return (
        <>
            <GradientBackground />
            <UnitContentWrapper>
                <StyledGrid>
                    <Cell gridColumn={['1 / -1', null, null, '1 / 5', null, '1 / 6']}>
                        <Heading>
                            <Title variant="large" elementType="h2">
                                <FormattedMessage defaultMessage="Ligging" />
                            </Title>
                        </Heading>
                        <LocationDescription maxLength={200} text={descriptionLocation} />
                        <NearbyDesktop>
                            <Heading>
                                <Title>
                                    <FormattedMessage defaultMessage="In de buurt" />
                                </Title>
                            </Heading>
                            <PointsOfInterest data={pointsOfInterest} />
                        </NearbyDesktop>
                    </Cell>
                    <Cell gridColumn={['1 / -1', null, null, '5 / -1', null, '6 / -1']}>
                        <LocationMap
                            position={{
                                longitude: lon,
                                latitude: lat,
                            }}
                            markerPosition={{ longitude: lon, latitude: lat }}
                        />
                    </Cell>
                    <NearbyMobile>
                        <PointsOfInterest data={pointsOfInterest} />
                    </NearbyMobile>
                </StyledGrid>
            </UnitContentWrapper>
        </>
    );
};

const PointsOfInterest: FC<React.PropsWithChildren<{ data: PointOfInterest[] }>> = props => {
    const { data } = props;

    const sortedPoints = useMemo(
        () =>
            [...data]
                // Sort distance from closest to farthest
                .sort((a, b) => a.distance - b.distance),
        [data]
    );
    return (
        <Points>
            {sortedPoints.map(({ name, distance }) => (
                <Point key={name}>
                    <Body>{name}</Body>
                    <Distance>
                        {distance < 1000 ? (
                            <>
                                <FormattedNumber value={distance} /> <FormattedMessage defaultMessage="meter" />
                            </>
                        ) : (
                            <>
                                <FormattedNumber value={Math.round(distance / 100) / 10} />{' '}
                                <FormattedMessage defaultMessage="kilometer" />
                            </>
                        )}
                    </Distance>
                </Point>
            ))}
        </Points>
    );
};

const UnitContentWrapper = styled(ContentWrapper)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-top: var(--sectionPadding);
    }
`;

const NearbyMobile = styled(Cell).attrs({ gridColumn: '1 / -1' })`
    display: none;
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.m - 1}px) {
        display: block;
    }
`;

const StyledGrid = styled(Grid)`
    > ${Cell} + ${Cell} {
        margin-top: ${({ theme }) => theme.spacing['50_Semi']};

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            margin-top: 0;
        }
    }
`;

const Distance = styled(Body).attrs(() => ({ variant: 'tiny' }))`
    margin-top: -4px;
    color: ${({ theme }) => theme.colors.neutral['60']};
    font-weight: 300;
    padding-bottom: ${({ theme }) => theme.spacing['30_Small']};
`;

const NearbyDesktop = styled.div<{ mobile?: boolean }>`
    display: none;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-top: 4rem;
        display: block;
    }
`;

const Point = styled.div`
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: ${({ theme }) => theme.spacing['20_Tiny']};
`;

const Points = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        column-count: 2;
    }
`;

const LocationDescription = styled(TextClamp).attrs({ variant: 'regular' })`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        max-width: 44.8rem;
    }
`;

export default memo(Location);
