import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box } from '@rebass/grid';
import dynamic from 'next/dynamic';
import React, { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { config } from 'react-spring';
import styled from 'styled-components';
import { getTripDuration, validBooking } from '../utils/trip';
import Spinner from './designsystem/Spinner';
import TitleWrapper from './designsystem/TitleWrapper';
import { PlannerProps } from './TripPlanner';

interface Props extends Pick<Sesame, 'onClose' | 'open'>, PlannerProps {
    name?: string;
    id?: string;
}

const Planner = dynamic(() => import('./TripPlanner'), { ssr: false, loading: () => <Spinner /> });

const TripPlannerModal: FC<React.PropsWithChildren<Props>> = memo(
    ({ onClose, name, open, id, booking, ...props }) => (
        <StyledModal config={{ ...config.gentle, duration: 200 }} variant="full" onClose={onClose} open={open}>
            <Content>
                <Planner
                    headerChildren={
                        name && (
                            <TitleWrapper
                                title={
                                    <>
                                        {validBooking(booking) && (
                                            <>
                                                <FormattedMessage
                                                    defaultMessage="{duration, number} {duration, plural, one {nacht} other {nachten}}"
                                                    values={{
                                                        duration: getTripDuration(
                                                            booking.arrivalDate,
                                                            booking.departureDate
                                                        ),
                                                    }}
                                                />
                                                {' — '}
                                            </>
                                        )}

                                        {name}
                                    </>
                                }
                            />
                        )
                    }
                    booking={booking}
                    {...props}
                />
            </Content>
        </StyledModal>
    ),
    (prev, next) => {
        if (!prev.open && !next.open) {
            return true;
        }
        return (Object.keys(next) as Array<keyof Props>).every(key => Object.is(prev[key], next[key]));
    }
);

export default TripPlannerModal;

const Content = styled(Box)`
    padding: 0rem 0.1rem;
    overflow: hidden;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-top: 4rem;
        overflow: visible;
        padding-bottom: 10rem;
    }
    width: 100%;
    max-width: 140.8rem;
    margin: auto;
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        height: 100%;
    }
`;

const StyledModal = styled(Modal)`
    && > [data-reach-dialog-overlay] {
        overflow: unset;
    }
    && > [data-reach-dialog-content] {
        padding: 0;
    }
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        padding: 0;
    }
`;
