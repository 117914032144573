import { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import { Booking } from '../entities/Booking';
import removeUndefinedEntries from '../utils/removeUndefinedEntries';
import ContentWrapper from './ContentWrapper';
import Formed from './Formed';
import SearchBar from './SearchBar';

interface Values {
    accommodationType: OptionType['value'];
    booking: Omit<Booking, 'trip'>;
}

interface Props {
    initialValues: Values;
    hasHero: boolean;
}

const HeroSearchBar = ({ initialValues, hasHero }: Props) => {
    const { push } = useRouter();
    const { brandConfig } = useTenantContext();

    if (brandConfig.searchBar?.hide) {
        return null;
    }
    return (
        <Formed<Values>
            skipPrompt
            initialValues={initialValues}
            handleSubmit={async ({ accommodationType, booking: newBooking }) => {
                await push({
                    pathname: `/search`,
                    query: removeUndefinedEntries({
                        ...newBooking,
                        accommodationType: accommodationType === 'all' ? undefined : accommodationType,
                    }),
                });
            }}
        >
            {() => (
                <SearchBarContainer className="hero-searchbar-container">
                    <ContentWrapper variant={hasHero ? 'searchList' : undefined}>
                        <SearchBar
                            hideSearchIcon
                            active={brandConfig.searchBar?.active ?? ['tripgroup', 'datepicker', 'accommodationType']}
                            variant="hero"
                        />
                    </ContentWrapper>
                </SearchBarContainer>
            )}
        </Formed>
    );
};

export default HeroSearchBar;

const SearchBarContainer = styled.div`
    top: 4rem;
    width: 100%;
    position: absolute;
`;
