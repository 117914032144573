/* eslint-disable import/prefer-default-export */

import { CART_KEY } from '../constants/storage';
import getStorageWithExpiry from './getStorageWithExpiry';

export const getCartIdFromLocal = () => getStorageWithExpiry('local')?.getItem(CART_KEY);

export const getCartIdFromSession = () => getStorageWithExpiry('session')?.getItem(CART_KEY);

export const getCartId = (disableStorageFallback?: boolean) => {
    const sessionCartId = getCartIdFromSession();
    const localeCartId = getCartIdFromLocal();
    if (disableStorageFallback) {
        return sessionCartId;
    }

    return sessionCartId ?? localeCartId;
};

export const setCartId = (cartId: string) => {
    getStorageWithExpiry('session')?.setItem(CART_KEY, cartId);
    getStorageWithExpiry('local')?.setItem(CART_KEY, cartId);
};

export const clearCartId = () => {
    const storageUtils = getStorageWithExpiry('session');
    storageUtils?.removeItem(CART_KEY);
    storageUtils?.removeItem('deskCart'); // is this still being used?
    getStorageWithExpiry('local')?.removeItem(CART_KEY);
};
