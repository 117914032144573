import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Separator from '@oberoninternal/travelbase-ds/components/section/Separator';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box, Flex } from '@rebass/grid';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { UnitDetailsQuery } from '../generated/graphql';
import ContentWrapper from './ContentWrapper';
import Cell from './designsystem/Cell';
import Grid from './designsystem/Grid';
import Heading from './designsystem/Heading';
import Intro from './Intro';
import UnitFeatures from './UnitFeatures';
import FreeCancelation from './svg/FreeCancelation.svg';
import Divider from './Divider';
import messages from '../constants/messages';
import createUnitDetailTitle from '../utils/createUnitDetailTitle';
import FavoriteButton from './FavoriteButton';
import { useTenantContext } from '../context/TenantContext';

interface Props {
    data: NonNullable<UnitDetailsQuery['rentalUnit']>;
}

const UnitIntro: FC<React.PropsWithChildren<Props>> = ({
    data: { name, tagline, descriptionLayout, accommodation, reviewStats, flexCancellationDays, id, ...data },
}) => {
    const intl = useIntl();
    const { brandConfig } = useTenantContext();
    return (
        <StyledWrapper>
            <Grid>
                <Intro
                    title={createUnitDetailTitle(name, accommodation.name, accommodation.hasPublicPage)}
                    rating={reviewStats?.count ? reviewStats?.average : undefined}
                    description={descriptionLayout}
                    tagline={tagline}
                    gridColumn="1 / -1"
                />

                {brandConfig.enableFavorites && (
                    <Box mb={[0, null, '64px']} mt={[8, null, 0]}>
                        <FavoriteButton type="rentalUnit" id={id} />
                    </Box>
                )}

                <Cell gridColumn="1 / -1" style={{ maxWidth: '102.4rem' }}>
                    {/* We only want to show this heading on mobile devices */}
                    <MobileWrapper>
                        <Separator />
                        <Heading>
                            <Title>
                                <FormattedMessage defaultMessage="Eigenschappen" />
                            </Title>
                        </Heading>
                    </MobileWrapper>
                    <UnitFeatures data={{ accommodation, ...data }} />
                </Cell>

                {flexCancellationDays !== null && (
                    <Cell gridColumn="1 / -1">
                        <Divider m={0} mt={5} />
                        <Item label={intl.formatMessage(messages.flexCancellation)}>
                            {intl
                                .formatMessage(messages.flexCancellationUntil, {
                                    flexCancellationDays,
                                })
                                .toLowerCase()}
                        </Item>
                    </Cell>
                )}
            </Grid>
        </StyledWrapper>
    );
};

export default UnitIntro;

const StyledWrapper = styled(ContentWrapper)`
    && {
        padding-top: 2.4rem;
        @supports (padding-top: clamp(1px, 1px, 1px)) {
            padding-top: clamp(1.6rem, 3vw, 3.6rem);
        }
    }
`;

const MobileWrapper = styled.div`
    width: 100%;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const Item: FC<React.PropsWithChildren<{ label: ReactNode }>> = ({ label, children }) => (
    <StyledBox flex={1} my={5} alignItems="center">
        <FreeCancelation />
        <Box px={3}>
            <Label>
                {label}&nbsp;
                {children}
            </Label>
        </Box>
    </StyledBox>
);

const StyledBox = styled(Flex)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        align-items: start;
    }
`;

const Label = styled(Body)`
    font-weight: 600;
`;
