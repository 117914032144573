/* eslint-disable @typescript-eslint/no-non-null-assertion */
import validInterval from '@oberoninternal/travelbase-ds/utils/validInterval';
import { gql } from '@apollo/client';
import addDays from 'date-fns/addDays';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import isEqual from 'lodash.isequal';
import { useMemo, useRef } from 'react';
import parseToDateString from '../constants/parseToDateString';
import {
    AlternativesInAccommodationQuery,
    UnitDetailsQuery,
    UnitSearchParamsInput,
    useAlternativesInAccommodationQuery,
    useUnitDetailInitialTripsQuery,
    useUnitDetailsQuery,
    useUnitDetailsSearchQuery,
} from '../generated/graphql';
import { convertToPeriod, getTripsFromPeriod } from '../utils/trip';
import { useTenantContext } from '../context/TenantContext';
import { StringParam, useQueryParams } from 'use-query-params';

export const pageQuery = gql`
    query UnitDetails($slug: String!, $arrivalDate: String, $bakedInFilterProperty: String!) {
        rentalUnit(slug: $slug) {
            id
            firstTripDate
            flexCancellationDays
            slug
            name
            brand
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
            jpegHero: mainImage {
                transform(config: HERO, format: JPEG) {
                    ...Transform
                }
            }
            webpHero: mainImage {
                transform(config: HERO, format: WEBP) {
                    ...Transform
                }
            }
            municipalRegistration
            tagline
            minOccupancy
            maxOccupancy
            petsAllowed
            descriptionLayout
            descriptionParticulars
            descriptionExtras
            cancellationInsuranceAvailable
            fullCircleImageUrl
            videoUrl

            hasBakedInFilterProperty: hasFilterProperty(handle: $bakedInFilterProperty)

            surcharges(arrivalDate: $arrivalDate) {
                ...Surcharge
            }

            reviewStats {
                ...ReviewStats
            }
            ...UnitFeatures
            ...UnitPhotos

            attributeCategories {
                ...Extras
            }

            accommodation {
                id
                slug
                ...UnitAccommodation
                descriptionGeneral
                ...UnitOwner
                ...Location
                ...AccommodationPhotos

                place
                name
                checkInEndTime
                checkInStartTime
                checkOutTime
                hasPublicPage
            }

            reviews(limit: 3, offset: 0) {
                ...UnitReviews
            }
        }
    }
    query UnitDetailsSearch(
        $slug: String!
        $params: UnitSearchParamsInput
        $arrivalDate: String
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id
            alternativesInAccommodation(params: $params) {
                hits {
                    ...SearchHit
                }
            }
            surcharges(arrivalDate: $arrivalDate) {
                ...Surcharge
            }
        }
    }

    query UnitDetailInitialTrips(
        $slug: String!
        $start: String!
        $end: String!
        $persons: Int!
        $pets: Int!
        $babies: Int!
    ) {
        rentalUnit(slug: $slug) {
            id
            trips(pets: $pets, persons: $persons, babies: $babies, startDate: $start, endDate: $end) {
                ...PlannerTrip
            }
        }
    }

    query AlternativesInAccommodation(
        $slug: String!
        $params: UnitSearchParamsInput
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id
            alternativesInAccommodation(params: $params) {
                hits {
                    ...SearchHit
                }
            }
        }
    }

    fragment Surcharge on RentalUnitSurcharge {
        id
        name
        calculation
        description
        maxAmount
        minAmount
        unitPrice
    }
`;

export type UnitDetailsRentalUnit = Maybe<
    UnitDetailsQuery['rentalUnit'] & AlternativesInAccommodationQuery['rentalUnit']
>;

/**
 * This hook merges the initial data with the search data after searching on the unit detail page
 */
const useUnitDetailsData = (slug: string, params: UnitSearchParamsInput | null) => {
    const initialParams = useRef(params);
    const paramsHaveChanged = !isEqual(initialParams.current, params);
    const { brandConfig } = useTenantContext();
    const [{ specialId }] = useQueryParams({ specialId: StringParam });

    const unitDetails = useUnitDetailsQuery({
        variables: {
            slug,
            arrivalDate: initialParams.current?.date,
            bakedInFilterProperty: brandConfig.bakedInFilterProperty ?? '',
        },
    });

    const accommodationAlternatives = useAlternativesInAccommodationQuery({
        variables: {
            slug,
            params: initialParams.current,
            badgeHandle: brandConfig.badge?.handle ?? '',
            badge2Handle: brandConfig.badge2?.handle ?? '',
        },
        ssr: false,
    });

    const search = useUnitDetailsSearchQuery({
        skip: !paramsHaveChanged,
        ssr: false,
        variables: {
            slug,
            params,
            arrivalDate: params?.date,
            badgeHandle: brandConfig.badge?.handle ?? '',
            badge2Handle: brandConfig.badge2?.handle ?? '',
        },
    });

    const { babies, date, duration, persons, pets } = initialParams.current ?? {};
    const initialTrips = useUnitDetailInitialTripsQuery({
        variables: {
            slug,
            babies: babies ?? 0,
            persons: persons ?? 2,
            pets: pets ?? 0,
            start: date!,
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            end: parseToDateString(addDays(new Date(date || new Date()), duration || 0)),
        },
        ssr: false,
        skip: !date || !duration,
    });

    const initialTrip = useMemo(() => {
        if (!initialTrips.data?.rentalUnit?.trips) {
            return undefined;
        }
        const period = convertToPeriod(initialTrips.variables?.start, initialTrips.variables?.end);

        if (!validInterval(period)) {
            return undefined;
        }

        const trips = getTripsFromPeriod(period, initialTrips.data.rentalUnit.trips);

        // If there is a specialId provided in the params, return the trip that matches the specialId
        if (specialId) {
            const tripThatMatchesSpecialId = trips.find(item => item.specialId === specialId);

            if (tripThatMatchesSpecialId) {
                return tripThatMatchesSpecialId;
            }
        }

        return trips[0];
        // we only want to change this reference on the basis of the initial trips, thus when loading is done
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialTrips.variables?.end, initialTrips.variables?.start, initialTrips.loading]);

    const rentalUnitData = useMemo(() => {
        if (!unitDetails.data?.rentalUnit) {
            return unitDetails.data?.rentalUnit;
        }

        if (search.data?.rentalUnit) {
            return {
                ...unitDetails.data?.rentalUnit,
                alternativesInAccommodation: search.data?.rentalUnit.alternativesInAccommodation,
                surcharges: search.data.rentalUnit.surcharges,
            };
        }

        return {
            ...unitDetails.data?.rentalUnit,
            alternativesInAccommodation: accommodationAlternatives.data?.rentalUnit?.alternativesInAccommodation,
        };
    }, [accommodationAlternatives.data, search.data, unitDetails.data]) as UnitDetailsRentalUnit;

    return {
        data: { rentalUnit: rentalUnitData, initialTrip },
        loading: unitDetails.loading,
        loadingAlternatives: search.loading,
        loadingInitialTrip: initialTrips.loading,
    };
};

export default useUnitDetailsData;
