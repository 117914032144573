import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Title, { TitleInner, TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';

interface Props {
    title: ReactNode;
    variant?: TitleVariant;
    textCentered?: boolean;
}

const TitleWrapper: FC<React.PropsWithChildren<Props>> = ({ title, variant = 'large', textCentered = false }) => (
    <Container textCentered={textCentered}>
        <Title variant={variant}>{title}</Title>
    </Container>
);

export default TitleWrapper;

const Container = styled.div<{ textCentered: boolean }>`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['20']};
        margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
        padding-bottom: ${({ theme }) => theme.spacing['40_Standard']};
        text-align: ${({ textCentered }) => (textCentered ? 'center' : 'initial')};
    }

    > ${TitleInner} {
        max-width: calc(100% - 2.4rem);
    }
`;
