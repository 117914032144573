import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import React, { ComponentProps, forwardRef, Ref } from 'react';
import { useTenantContext } from '../../context/TenantContext';

/**
 * Pretty much the same as `Button` from the designsystem but with the difference that the variant depends on de brandconfig
 */
const BookButton = (props: ComponentProps<typeof Button>, ref: Ref<HTMLButtonElement>) => {
    const { brandConfig } = useTenantContext();
    return <Button variant={brandConfig.bookButtonVariant} ref={ref} {...props} />;
};

export default forwardRef(BookButton);
