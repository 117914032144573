import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box, Flex } from '@rebass/grid';
import React, { FC, ReactNode, useMemo } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import styled from 'styled-components';
import surchargeCalculationTexts from '../constants/surchargeCalculationTexts';
import { SurchargeCalculationEnum, SurchargeFragment, UnitDetailsQuery } from '../generated/graphql';
import Heading from './designsystem/Heading';
import ContentWrapper from './ContentWrapper';
import TextClamp from './designsystem/TextClamp';
import messages from '../constants/messages';

interface Props {
    data: NonNullable<UnitDetailsQuery['rentalUnit']>;
}

const Surcharge = ({ surcharge: { name, description, unitPrice, calculation } }: { surcharge: SurchargeFragment }) => (
    <Flex key={name} justifyContent="space-between">
        <Box flex={1}>
            {name}
            <Body variant="small">{description}</Body>
        </Box>
        <Box ml={3}>
            <PriceBody variant="large">
                {calculation !== SurchargeCalculationEnum.Subsequent && (
                    <>
                        <FormattedNumber value={unitPrice} format="EUR" />
                        <br />
                    </>
                )}
                {surchargeCalculationTexts[calculation]}
            </PriceBody>
        </Box>
    </Flex>
);

const UnitParticulars: FC<React.PropsWithChildren<Props>> = ({
    data: {
        descriptionParticulars,
        accommodation: { checkInStartTime, checkInEndTime, checkOutTime },
        surcharges,
        flexCancellationDays,
    },
}) => {
    const { onClose, open, onOpen } = useSesame();
    const intl = useIntl();
    const [ruOptions, ruSurcharges] = useMemo(() => {
        const opts = [];
        const sur = [];

        for (const surcharge of surcharges || []) {
            if (surcharge) {
                if (surcharge.minAmount === 1 && surcharge.maxAmount === 1) {
                    sur.push(surcharge);
                } else {
                    opts.push(surcharge);
                }
            }
        }

        return [opts, sur] as const;
    }, [surcharges]);

    return (
        <>
            <ContentWrapper>
                <Content>
                    <Heading>
                        <Title variant="large" elementType="h2">
                            <FormattedMessage defaultMessage="Belangrijk om te weten" />
                        </Title>
                    </Heading>
                    {descriptionParticulars && (
                        <TextClamp variant="regular" maxLength={200} text={descriptionParticulars} />
                    )}
                    <Box p={4}>
                        {checkInStartTime === checkInEndTime ? (
                            <Item label={<FormattedMessage defaultMessage="Check in:" />}>
                                {checkInStartTime}
                                <FormattedMessage defaultMessage="uur" />
                            </Item>
                        ) : (
                            <Item label={<FormattedMessage defaultMessage="Check in tussen:" />}>
                                {checkInStartTime} <FormattedMessage defaultMessage="uur" />
                                {' - '}
                                {checkInEndTime} <FormattedMessage defaultMessage="uur" />
                            </Item>
                        )}
                        <Item label={<FormattedMessage defaultMessage="Check uit voor:" />}>
                            {checkOutTime} <FormattedMessage defaultMessage="uur" />
                        </Item>
                    </Box>
                    {flexCancellationDays !== null && (
                        <StyledBox p={4}>
                            <Item label={intl.formatMessage(messages.flexCancellation)}>
                                <Box pb={2}>
                                    <Body style={{ fontWeight: 600 }}>
                                        {intl.formatMessage(messages.flexCancellationUntil, {
                                            flexCancellationDays,
                                        })}
                                    </Body>
                                    <Body style={{ paddingTop: '0.8rem' }}>
                                        <FormattedMessage
                                            defaultMessage="Gratis annuleren kan bij deze accommodatie tot {flexCancellationDays} dagen voor aankomst. Eventuele reserveringskosten en verzekeringspremie worden bij annulering altijd in rekening gebracht."
                                            values={{
                                                flexCancellationDays,
                                            }}
                                        />
                                    </Body>
                                </Box>
                            </Item>
                        </StyledBox>
                    )}
                    {surcharges.length > 0 && (
                        <Button variant="outline" onClick={onOpen}>
                            <FormattedMessage defaultMessage="Opties en bijkomende kosten" />
                        </Button>
                    )}
                </Content>
            </ContentWrapper>
            <Modal onClose={onClose} open={open} variant="regular">
                <ModalHeader variant="regular">
                    <FormattedMessage defaultMessage="Opties en bijkomende kosten" />
                </ModalHeader>

                <Surcharges>
                    <Body>
                        <FormattedMessage defaultMessage="Eventuele opties kun je toevoegen in stap 1 van het boeken van deze accommodatie" />
                    </Body>
                    {ruSurcharges.length > 0 && (
                        <SubHeader>
                            <FormattedMessage defaultMessage="Verplichte bijkomende kosten" />
                        </SubHeader>
                    )}
                    {ruSurcharges.map(surcharge => (
                        <Surcharge key={surcharge.name} surcharge={surcharge} />
                    ))}
                    {ruOptions.length > 0 && (
                        <SubHeader>
                            <FormattedMessage defaultMessage="Optioneel" />
                        </SubHeader>
                    )}
                    {ruOptions.map(surcharge => (
                        <Surcharge key={surcharge.name} surcharge={surcharge} />
                    ))}
                </Surcharges>
            </Modal>
        </>
    );
};

export default UnitParticulars;

const PriceBody = styled(Body)`
    text-align: right;
`;

const Surcharges = styled.div`
    margin-top: ${({ theme }) => theme.spacing['40_Standard']};
    > div + div {
        margin-top: ${({ theme }) => theme.spacing['40_Standard']};
    }
`;

const Item: FC<React.PropsWithChildren<{ label: ReactNode }>> = ({ label, children }) => (
    <Flex flex={1}>
        <Box width="18rem">
            <Label>{label}</Label>
        </Box>
        <Flex flex={1}>{children}</Flex>
    </Flex>
);

const Label = styled(Body)`
    font-weight: 600;
`;

const Content = styled(Box).attrs({ width: 1 })`
    max-width: 80.8rem;

    > * + * {
        margin-top: ${({ theme }) => theme.spacing['40_Standard']};
        @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
            margin-top: ${({ theme }) => theme.spacing['50_Semi']};
        }
    }
`;

const SubHeaderBody = styled(Body).attrs({ variant: 'large' })`
    font-weight: 600;
`;

const SubHeaderContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    padding-bottom: 1.6rem;
    margin-top: 2.4rem !important;
`;

const SubHeader: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
    <SubHeaderContainer>
        <SubHeaderBody>{children}</SubHeaderBody>
    </SubHeaderContainer>
);

const StyledBox = styled(Box)`
    background: ${({ theme }) => theme.colors.neutral['10']};
    border-radius: ${({ theme }) => theme.radius.textInput};
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        > div:first-of-type {
            flex-wrap: wrap;
            > div:first-of-type {
                width: 100%;
            }
        }
    }
`;
