/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

import { defineMessages, IntlShape } from 'react-intl';
import { ActivityDetailQuery, CompanyDetailQuery, UnitDetailsQuery } from '../generated/graphql';
import getUnitTypeConsts from './getUnitTypeConsts';

const messages = defineMessages({
    unitDetailTitle: {
        defaultMessage: '{unitName}',
    },
    unitDetailDescription: {
        defaultMessage: '{unitDescription}',
    },
    companyTitle: {
        defaultMessage: '{companyName}',
    },
    companyDescription: {
        defaultMessage: '{companyDescription}',
    },
    activityTitle: {
        defaultMessage: '{activityTitle}',
    },
    activityDescription: {
        defaultMessage: '{activityDescription}',
    },
});

export const getUnitDetailSeoMessages = (data: UnitDetailsQuery['rentalUnit'], intl: IntlShape) => {
    const unitTypeConsts = getUnitTypeConsts(intl);
    const type = data?.type ? unitTypeConsts[data.type].translation : '';

    return {
        title: intl.formatMessage(messages.unitDetailTitle, {
            unitName: data?.name,

            // yes, these are not used by the defaultMessage.
            // They are in use however in other projects (texel/teschelling) so DO NOT REMOVE THESE.
            type,
            tagline: data?.tagline,
            place: data?.accommodation.place,
        }) as string,
        description: intl.formatMessage(messages.unitDetailDescription, {
            unitDescription: data?.descriptionLayout,
            type,
            tagline: data?.tagline,
        }) as string,
    };
};

export const getCompanySeoMessages = (data: CompanyDetailQuery['company'], intl: IntlShape) => ({
    title: intl.formatMessage(messages.companyTitle, {
        companyName: data?.name,
        place: data?.place,
        categories: data?.categories.map(category => category.name).join(', '),
    }) as string,
    description: intl.formatMessage(messages.companyDescription, {
        companyDescription: data?.description,
        companyShortDescription: data?.description?.slice(0, 155),
        name: data?.name,
        city: data?.address.city,
    }) as string,
});

export const getActivitySeoMessages = (data: ActivityDetailQuery['activity'], intl: IntlShape) => ({
    title: intl.formatMessage(messages.activityTitle, {
        activityTitle: data?.name,
        place: data?.place,
        type: data?.themes.map(theme => theme.name).join(', '),
    }) as string,
    description: intl.formatMessage(messages.activityDescription, {
        activityDescription: data?.description,
        activityShortDescription: data?.description?.slice(0, 155),
        name: data?.name,
        place: data?.place,
    }) as string,
});
