import Modal, { ModalProps } from '@oberoninternal/travelbase-ds/components/layout/Modal';
import React, { FC, useEffect, useRef } from 'react';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box, Flex } from '@rebass/grid';
import Spinner from '@oberoninternal/travelbase-ds/components/figure/Spinner';
import getLogoForBrand from '@oberoninternal/travelbase-ds/utils/getLogoForBrand';

interface Props extends ModalProps {
    redirectUrl: string;
    brand: string;
}

const brandNames: Record<string, string> = {
    ameland: 'Ameland',
    oberon: 'Oberon',
    schier: 'VVV Schiermonnikoog',
    terschelling: 'VVV Terschelling',
    texel: 'VVV Texel',
    waterland: 'VVV Waterland van Friesland',
};

const RedirectModal: FC<React.PropsWithChildren<Props>> = ({ redirectUrl, brand, ...rest }) => {
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const { onClose } = rest;
    useEffect(() => {
        if (redirectUrl) {
            timer.current = setTimeout(() => {
                window.location.href = redirectUrl;
                onClose();
            }, 5000);
        }

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [redirectUrl, onClose]);

    const name = brandNames[brand];

    return (
        <Modal {...rest} variant="regular">
            <Flex flexDirection="column" height="100%">
                <ModalHeader variant="small">
                    <Box width={brand === 'waterland' ? 328 : 164} mb={2} mt={-4}>
                        {getLogoForBrand()}
                    </Box>
                </ModalHeader>
                <Flex flexDirection="column" justifyContent="center" flex={1}>
                    <Box mt={4}>
                        <Body variant="large">Je maakt de reservering compleet via onze partner {name}.</Body>
                    </Box>
                    <Box mt={4}>
                        <Flex alignItems="center">
                            <Spinner style={{ minWidth: '24px', minHeight: '24px' }} />
                            <Box ml={4}>
                                <Body variant="large">
                                    Binnen enkele seconden sturen we je automatisch door naar het reserveringsformulier.
                                </Body>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        </Modal>
    );
};

export default RedirectModal;
