import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import getUnitTypeConsts from '../constants/getUnitTypeConsts';
import { UnitFeaturesFragment } from '../generated/graphql';
import Grid from './designsystem/Grid';
import AmountBathrooms from './svg/AmountBathrooms.svg';
import AmountBedrooms from './svg/AmountBedrooms.svg';
import AmountToilets from './svg/AmountToilets.svg';
import Code from './svg/Code.svg';
import LivingArea from './svg/LivingArea.svg';
import AccommodationLocation from './svg/AccommodationLocation.svg';
import MaxOccupancy from './svg/MaxOccupancy.svg';
import OutdoorArea from './svg/OutdoorArea.svg';
import PetsAllowed from './svg/PetsAllowed.svg';
import PetsProhibited from './svg/PetsProhibited.svg';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';

export const fragment = gql`
    fragment UnitFeatures on RentalUnit {
        livingArea
        outdoorArea
        maxOccupancy
        amountBedrooms
        petsAllowed
        amountBathrooms
        amountToilets
        code
        type
        accommodation {
            place
        }
    }
`;

interface Props {
    data: UnitFeaturesFragment;
}

const UnitFeatures: FC<React.PropsWithChildren<Props>> = ({
    data: {
        livingArea,
        outdoorArea,
        maxOccupancy,
        amountBedrooms,
        petsAllowed,
        amountBathrooms,
        amountToilets,
        code,
        accommodation,
        type,
    },
}) => {
    const intl = useIntl();
    const unitTypeConsts = getUnitTypeConsts(intl);
    return (
        <Features>
            <Feature style={{ textTransform: 'capitalize' }}>
                {unitTypeConsts[type].icon}
                {unitTypeConsts[type].translation}
            </Feature>
            <Feature>
                <AccommodationLocation />
                <div title={accommodation.place}>{accommodation.place}</div>
            </Feature>
            <Feature>
                <MaxOccupancy />
                <FormattedMessage
                    defaultMessage="{maxOccupancy, number}
                {maxOccupancy, plural,
                    one {persoon}
                    other {personen}
                }"
                    values={{ maxOccupancy }}
                />
            </Feature>
            {petsAllowed ? (
                <Feature>
                    <PetsAllowed />
                    <FormattedMessage
                        defaultMessage="
                    {petsAllowed, number} {petsAllowed, plural,
                        one {huisdier}
                        other {huisdieren}
                    } max."
                        values={{ petsAllowed }}
                    />
                </Feature>
            ) : (
                <Feature>
                    <PetsProhibited />
                    <FormattedMessage defaultMessage="Geen huisdieren" />
                </Feature>
            )}
            {amountBathrooms !== null && (
                <Feature>
                    <AmountBathrooms />
                    <FormattedMessage
                        defaultMessage="
                    {amountBathrooms, number} {amountBathrooms, plural,
                        one {badkamer}
                        other {badkamers}
                    }"
                        values={{ amountBathrooms }}
                    />
                </Feature>
            )}
            {amountToilets !== null && (
                <Feature>
                    <AmountToilets />
                    <FormattedMessage
                        defaultMessage="
                    {amountToilets, number} {amountToilets, plural,
                        one {toilet}
                        other {toiletten}
                    }"
                        values={{ amountToilets }}
                    />
                </Feature>
            )}
            {amountBedrooms !== null && (
                <Feature>
                    <AmountBedrooms />
                    <FormattedMessage
                        defaultMessage="
                    {amountBedrooms, number} {amountBedrooms, plural,
                        one {slaapkamer}
                        other {slaapkamers}
                    }"
                        values={{ amountBedrooms }}
                    />
                </Feature>
            )}
            {livingArea !== null && (
                <Feature>
                    <LivingArea />
                    <FormattedMessage defaultMessage="{livingArea, number}m² binnenopp." values={{ livingArea }} />
                </Feature>
            )}
            {(outdoorArea ?? 0) > 0 && (
                <Feature>
                    <OutdoorArea />
                    <FormattedMessage defaultMessage="{outdoorArea, number}m² buitenopp." values={{ outdoorArea }} />
                </Feature>
            )}
            <Feature>
                <Code />
                <FormattedMessage defaultMessage="Accommodatie {code}" values={{ code }} />
            </Feature>
        </Features>
    );
};

const Features = styled(Grid)`
    grid-row-gap: 1.6rem;
`;

const Feature = styled.div`
    display: flex;
    align-items: center;
    grid-column: span 2;
    white-space: nowrap;
    ${({ theme }) => getFontCss(theme.fontSizes.body.regular)};

    svg {
        min-width: 2rem;
        min-height: 2rem;
        margin-right: 1rem;

        g path {
            stroke-width: 1.3;
        }
    }

    > div {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        grid-column: span 4;
    }
`;

export default UnitFeatures;
