import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Separator from '@oberoninternal/travelbase-ds/components/section/Separator';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { gql } from '@apollo/client';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled, { css } from 'styled-components';
import { Nullable } from '../entities/Nullable';
import { ReviewStatsFragment } from '../generated/graphql';
import Grid from './designsystem/Grid';
import FormattedReviewTerm from './FormattedReviewTerm';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';

export const reviewStatsFragment = gql`
    fragment ReviewStats on ReviewStats {
        count
        average
        location
        hospitality
        layout
        maintenance
        valueForMoney
    }
`;

interface Props {
    reviewStats: Nullable<ReviewStatsFragment>;
    variant?: 'dark' | 'light';
    title?: ReactNode;
}

const isNumber = (some: Maybe<number>): some is number => typeof some === 'number' && some >= 0;

const ReviewStats: FC<React.PropsWithChildren<Props>> = ({
    reviewStats: { average, count, location, maintenance, hospitality, valueForMoney, layout },
    variant = 'dark',
    title,
}) => (
    <>
        {title && <StyledTitle>{title}</StyledTitle>}
        {isNumber(count) && (
            <Container>
                <RatingGrid>
                    {isNumber(average) && (
                        <Rating variant={variant}>
                            <RatingScore>
                                <FormattedNumber value={average} style="decimal" />
                            </RatingScore>
                        </Rating>
                    )}

                    <RatingTitle>
                        <Byline>
                            <>
                                <FormattedNumber value={count} /> <FormattedMessage defaultMessage="beoordelingen" />
                            </>
                        </Byline>
                        {isNumber(average) && (
                            <ReviewTerm style={!isNumber(count) ? { marginTop: '3.2rem' } : undefined}>
                                <FormattedReviewTerm average={average} />
                            </ReviewTerm>
                        )}
                    </RatingTitle>
                </RatingGrid>

                <Grid>
                    <Content>
                        <CriteriumContainer>
                            {isNumber(location) && (
                                <CriteriumCell>
                                    <Criterium>
                                        <FormattedMessage defaultMessage="Ligging" />
                                    </Criterium>
                                    <CriteriumRating>
                                        <FormattedNumber value={location} />
                                    </CriteriumRating>
                                </CriteriumCell>
                            )}
                            {isNumber(maintenance) && (
                                <CriteriumCell>
                                    <Criterium>
                                        <FormattedMessage defaultMessage="Onderhoud" />
                                    </Criterium>
                                    <CriteriumRating>
                                        <FormattedNumber value={maintenance} />
                                    </CriteriumRating>
                                </CriteriumCell>
                            )}
                            {isNumber(hospitality) && (
                                <CriteriumCell>
                                    <Criterium>
                                        <FormattedMessage defaultMessage="Gastvrijheid" />
                                    </Criterium>
                                    <CriteriumRating>
                                        <FormattedNumber value={hospitality} />
                                    </CriteriumRating>
                                </CriteriumCell>
                            )}
                            {isNumber(valueForMoney) && (
                                <CriteriumCell>
                                    <Criterium>
                                        <FormattedMessage defaultMessage="Prijs/kwaliteit" />
                                    </Criterium>
                                    <CriteriumRating>
                                        <FormattedNumber value={valueForMoney} />
                                    </CriteriumRating>
                                </CriteriumCell>
                            )}
                            {isNumber(layout) && (
                                <CriteriumCell>
                                    <Criterium>
                                        <FormattedMessage defaultMessage="Inrichting" />
                                    </Criterium>
                                    <CriteriumRating>
                                        <FormattedNumber value={layout} />
                                    </CriteriumRating>
                                </CriteriumCell>
                            )}
                        </CriteriumContainer>
                        <ReviewSeparator />
                    </Content>
                </Grid>
            </Container>
        )}
    </>
);

const ReviewSeparator = styled(Separator)`
    margin: ${({ theme }) => theme.spacing['50_Semi']} 0;
`;

const StyledTitle = styled(Title).attrs({ variant: 'large', elementType: 'h2' })`
    margin-bottom: 4.8rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        > ${Grid} {
            display: flex;
            flex-wrap: wrap;
        }
    }
`;

const RatingTitle = styled.div`
    padding-bottom: 2.6rem;
    grid-column: 2 / -1;
`;

const RatingScore = styled(Title).attrs({ variant: 'large' })`
    position: relative;
`;

const Rating = styled.div<{ variant: 'light' | 'dark' }>`
    display: flex;
    justify-content: center;
    position: relative;
    grid-column: span 1;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        min-width: 8rem;
        margin-right: 2.82%;
    }

    ${({ variant, theme }) =>
        variant === 'dark'
            ? css`
                  ::before {
                      background-color: ${theme.colorAssignments.rating
                          ? getAssignmentColor(theme, theme.colorAssignments.rating)
                          : theme.colors.secondary['30']};
                  }
                  ${RatingScore} {
                      color: ${theme.colors.neutral['0']};
                  }
              `
            : css`
                  ::before {
                      box-shadow: inset 0 0 0 2px ${theme.colors.secondary['0']};
                      background: ${theme.colors.neutral['0']};
                  }

                  ${RatingScore} {
                      color: ${theme.colors.secondary['30']};
                  }
              `};

    :before {
        width: 100%;
        content: '';
        padding-top: 100%;
        border-radius: 50%;
        position: absolute;
        margin-top: -2%;
    }
`;

const RatingGrid = styled(Grid)`
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: flex;
    }
`;

const CriteriumRating = styled(Title).attrs({ variant: 'small', elementType: 'div' })`
    font-weight: 500;
`;

const Content = styled.div`
    grid-column: 2 / -1;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        grid-column: 1 / -1;
        width: 100%;
        margin-top: 4rem;
    }
`;

const Byline = styled(Body).attrs({ variant: 'tiny', elementType: 'div' })`
    margin-bottom: ${({ theme }) => theme.spacing['30_Small']};
`;

const ReviewTerm = styled(Title).attrs({ variant: 'large', elementType: 'div' })`
    color: ${({ theme }) => theme.colors.neutral['90']};
    font-weight: 300;
`;

const Criterium = styled(Body).attrs({ elementType: 'div' })`
    color: ${({ theme }) => theme.colors.neutral['90']};
    font-weight: 300;
`;

const CriteriumCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    grid-column: 1 / -1;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        grid-column: span 2;
    }
`;

const CriteriumContainer = styled(Grid)`
    margin-bottom: 3.2rem;
`;

export default ReviewStats;
