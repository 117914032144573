import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import accommodationImageCategoryTexts from '../constants/accommodationImageCategoryTexts';
import { AccommodationImageCategoryEnum } from '../generated/graphql';
import Tile, { TileProps } from './designsystem/Tile';

interface Props extends TileProps {
    category?: string;
    count?: number;
    onClick?: () => void;
}

const PhotoCategory: FC<React.PropsWithChildren<Props>> = ({ count, category, onClick, ...photoProps }) => (
    <Wrapper>
        <Container onClick={onClick}>
            <Tile loading="lazy" {...photoProps} />
            <Text>
                <Title variant="tiny">
                    {accommodationImageCategoryTexts[category as AccommodationImageCategoryEnum]}
                </Title>
                <Body variant="small">
                    <FormattedMessage
                        defaultMessage={`{count, number} {count, plural, one {foto} other {foto's}}`}
                        values={{ count }}
                    />
                </Body>
            </Text>
        </Container>
    </Wrapper>
);

const Wrapper = styled.div`
    scroll-snap-align: start;

    & + & {
        margin-left: 3.2rem;
    }

    &:last-child {
        margin-right: 3.2rem;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 32rem;
    cursor: pointer;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 44.8rem;
        height: 100%;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing['40_Standard']};
`;

export default PhotoCategory;
