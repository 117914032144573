import React, { FC } from 'react';
import { ModalProps } from '@oberoninternal/travelbase-ds/components/layout/Modal';
import MediaModal from './MediaModal';
import Iframe from 'react-iframe';

interface Props extends ModalProps {
    src?: string | null;
}

const FullCircleModal: FC<React.PropsWithChildren<Props>> = ({ open, onClose, src }) => {
    const srcUrl = src ?? '';
    return (
        <MediaModal open={open} onClose={onClose}>
            <Iframe url={srcUrl} width="100%" height="100%" display="block" position="relative" />
        </MediaModal>
    );
};

export default FullCircleModal;
