import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box, Flex } from '@rebass/grid';
import dynamic from 'next/dynamic';
import React, { FC, memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Booking } from '../entities/Booking';
import usePlannerPeriod from '../hooks/planner/usePlannerPeriod';
import { convertToPeriod } from '../utils/trip';
import ContentWrapper from './ContentWrapper';
import BookButton from './designsystem/BookButton';
import Heading from './designsystem/Heading';
import { PlannerProps } from './TripPlanner';
import UnitDetailsPlannerModal from './UnitDetailsPlannerModal';
import { TripContent } from './UnitStickyBar';

const Planner = dynamic(() => import('./TripPlanner'), { ssr: false });
interface Props extends Pick<Sesame, 'onClose' | 'open' | 'onOpen'> {
    slug: string;
    name?: string;
    id: string;
    booking: Booking;
    onBookingChange: (booking: Booking) => void;
    onBookingReservation: () => Promise<void>;
    reserving: boolean;
    maxOccupancy: number;
    minOccupancy: number;
    petsAllowed: number;
    type: 'accommodation' | 'unit';
    firstTripDate?: Date;
    loading: boolean;
}

const UnitAvailability: FC<React.PropsWithChildren<Props>> = ({
    open,
    onClose,
    name,
    id,
    slug,
    booking,
    onBookingChange,
    onBookingReservation,
    reserving,
    maxOccupancy,
    type,
    minOccupancy,
    onOpen,
    petsAllowed,
    firstTripDate,
    loading,
}) => {
    const plannerPeriodProps = usePlannerPeriod(convertToPeriod(booking.arrivalDate, booking.departureDate));
    const deviceSize = useDeviceSize();
    const [ref, inView] = useInView({ triggerOnce: true });
    const { arrivalDate, departureDate, trip } = booking;
    const bookingIsValid = !!(arrivalDate && departureDate);
    return (
        <ContentWrapper ref={ref}>
            <Flex flexDirection="column" width={1}>
                <div className="gt-m">
                    <Heading>
                        <Title variant="large">
                            <FormattedMessage defaultMessage="Beschikbaarheid en prijzen" />
                        </Title>
                    </Heading>
                    <Body style={{ marginBottom: '5.6rem' }}>
                        <FormattedMessage defaultMessage="Selecteer een aankomst- en vertrekdatum" />
                    </Body>
                </div>

                <Title className="lt-m" variant="large">
                    <Box mb="2.8rem">
                        {bookingIsValid ? (
                            <FormattedMessage defaultMessage="Reserveren" />
                        ) : (
                            <FormattedMessage defaultMessage="Beschikbaarheid en prijzen" />
                        )}
                    </Box>
                </Title>
                {!bookingIsValid && (
                    <Body className="lt-m" style={{ marginBottom: '5.6rem' }}>
                        <FormattedMessage defaultMessage="Selecteer een aankomst- en vertrekdatum" />
                    </Body>
                )}
                <BookingButtonWrapper>
                    <TripContent booking={booking} plannerIsOpen={open} openModal={onOpen} loading={loading} />

                    <BookingButton
                        size="large"
                        submitting={reserving}
                        disabled={open && !trip}
                        onClick={trip ? onBookingReservation : onOpen}
                        data-cy="bookingButton"
                    >
                        {bookingIsValid && <FormattedMessage defaultMessage="Reserveren" />}
                        {!bookingIsValid && <FormattedMessage defaultMessage="Beschikbaarheid en prijzen" />}
                    </BookingButton>
                </BookingButtonWrapper>
                {bookingIsValid && (
                    <TextButton onClick={onOpen} className="lt-m" style={{ marginTop: '1.6rem', width: 'fit-content' }}>
                        <FormattedMessage defaultMessage="Aankomst- of vertrekdag wijzigen" />
                    </TextButton>
                )}
                {deviceSize && deviceSize !== 'mobile' && (
                    <InlinePlanner
                        modalIsOpen={open}
                        slug={slug}
                        booking={booking}
                        setBooking={onBookingChange}
                        maxOccupancy={maxOccupancy}
                        minOccupancy={minOccupancy}
                        petsAllowed={petsAllowed}
                        lazyLoading={!inView}
                        reserving={reserving}
                        type={type}
                        onBookingReservation={onBookingReservation}
                        initialCenterMonth={firstTripDate}
                        {...plannerPeriodProps}
                    />
                )}
            </Flex>
            <UnitDetailsPlannerModal
                id={id}
                name={name}
                onClose={onClose}
                open={open}
                slug={slug}
                booking={booking}
                setBooking={onBookingChange}
                reserving={reserving}
                onBookingReservation={onBookingReservation}
                minOccupancy={minOccupancy}
                maxOccupancy={maxOccupancy}
                petsAllowed={petsAllowed}
                initialCenterMonth={firstTripDate}
                {...plannerPeriodProps}
            />
        </ContentWrapper>
    );
};

export default UnitAvailability;

const InlinePlanner = memo(
    (props: PlannerProps & { modalIsOpen: boolean; reserving?: boolean }) => <Planner {...props} />,
    (prev, next) => {
        // We don't want to rerender two planners at the same time
        if (next.modalIsOpen) {
            return true;
        }
        return (Object.keys(next) as Array<keyof PlannerProps>).every(key => Object.is(prev[key], next[key]));
    }
);

const BookingButtonWrapper = styled(Flex)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const BookingButton = styled(BookButton)`
    margin-left: auto;
    flex-grow: 1;
    margin-left: 0;
`;
