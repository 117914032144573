import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Img from './Img';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { Box, Flex } from '@rebass/grid';
import { gql } from '@apollo/client';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { UnitAccommodationFragment } from '../generated/graphql';
import createPlaceholder from '../utils/createPlaceholder';
import ContentWrapper from './ContentWrapper';
import Heading from './designsystem/Heading';
import TextClamp from './designsystem/TextClamp';
import Stack from './Stack';
import { useRouter } from 'next/router';

export const fragment = gql`
    fragment UnitAccommodation on Accommodation {
        slug
        jpegMain: mainImage {
            transform(config: HERO, format: JPEG) {
                ...Transform
            }
        }
        webpMain: mainImage {
            transform(config: HERO, format: WEBP) {
                ...Transform
            }
        }
        descriptionGeneral
        name
    }
`;

interface Props {
    data: UnitAccommodationFragment;
}
const UnitAccommodation = ({ data: { jpegMain, name, webpMain, descriptionGeneral, slug } }: Props) => {
    const {
        query: { searched },
    } = useRouter();
    const renderAccommodationButton = (className: string) => (
        <Link href={{ pathname: `/complex/${slug}`, query: { searched } }} passHref legacyBehavior>
            <Button style={{ display: 'inline-flex' }} as="a" className={className} variant="outline">
                <FormattedMessage defaultMessage="Bekijk {name}" values={{ name }} />
            </Button>
        </Link>
    );
    return (
        <ContentWrapper>
            <Wrapper flexDirection={['column', null, null, 'row']} mb={[5, null, null, 0]}>
                {jpegMain && webpMain && (
                    <Stack spacing={5} flex={1} style={{ display: 'block' }}>
                        <ImageContainer>
                            <Img
                                fallback={{ src: jpegMain?.transform?.src }}
                                sources={[
                                    {
                                        srcSet: webpMain?.transform?.srcSet,
                                        type: 'image/webp',
                                    },
                                    {
                                        srcSet: jpegMain?.transform?.srcSet,
                                        type: 'image/jpeg',
                                    },
                                ]}
                                placeholder={createPlaceholder(webpMain?.transform?.placeholder)}
                                loading="lazy"
                                layout="fill"
                            />
                        </ImageContainer>
                        {renderAccommodationButton('gt-m')}
                    </Stack>
                )}
                <Box flex={1} pt={7}>
                    <Heading>
                        <Title variant="large">{name}</Title>
                    </Heading>
                    {descriptionGeneral && <TextClamp alwaysClamp text={descriptionGeneral} />}
                </Box>
            </Wrapper>
            {renderAccommodationButton('lt-m')}
        </ContentWrapper>
    );
};

const Wrapper = styled(Flex)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        > div:nth-child(2) {
            padding-left: 8rem;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        > div:nth-child(2) {
            padding-left: 15rem;
        }
    }
`;
const ImageContainer = styled.div`
    display: inline-block;
    width: 100%;
    ::after {
        padding-top: calc(9 / 16 * 100%);
        content: '';
        display: block;
    }
    position: relative;

    img {
        object-fit: cover;
    }
`;
export default UnitAccommodation;
