import ArrowRight from '@oberoninternal/travelbase-ds/components/figure/ArrowRight';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import Link, { LinkProps } from 'next/link';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import HSeperator from './svg/HSeperator.svg';

const BreadCrumbs: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
    <Container>
        <ul>{children}</ul>
    </Container>
);

const PrevLink = (props: LinkProps) => (
    <PrevItem>
        <StyledArrowRight height="10" style={{ transform: 'rotate(180deg)' }} />
        <Link {...props} passHref legacyBehavior>
            <FormattedMessage defaultMessage="Terug" />
        </Link>
        <StyledHSeperator className="gt-s" />
    </PrevItem>
);

const isInternalLink = (href?: LinkProps['href']) => {
    if (typeof href !== 'string') {
        return true;
    }
    try {
        return !new URL(href);
    } catch (error) {
        return false;
    }
};

interface CrumbProps extends Partial<LinkProps> {
    active?: boolean;
    className?: string;
}

const Crumb: FC<React.PropsWithChildren<CrumbProps>> = ({ active, href, className, ...props }) => {
    const isInternal = isInternalLink(href);
    return (
        <Item className={className}>
            {active && props.children}
            {!active && (
                <>
                    {!isInternal && <a href={href as string}>{props.children}</a>}
                    {isInternal && href && <Link href={href} {...props} legacyBehavior />}
                </>
            )}
            <StyledArrowRight height="10" />
        </Item>
    );
};

const Container = styled.div`
    max-width: calc(140.8rem + var(--wrapperPadding) * 2);
    padding: 0 var(--wrapperPadding);
    margin: 0 auto;
    > ul {
        align-items: center;
        margin-bottom: 0;
        margin-top: 1.8rem;
        padding: 0;
        display: flex;
    }

    li {
        align-items: center;
        display: flex;
        ${({ theme }) => getFontCss(theme.fontSizes.body.small)};

        :last-of-type {
            svg {
                display: none;
            }
        }
    }
`;

export default Object.assign(BreadCrumbs, { PrevLink, Crumb });

const Item = styled.li`
    color: ${({ theme }) => theme.colors.neutral[40]};

    a {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.neutral[60]};
        text-decoration: none !important;

        :hover {
            text-decoration: underline !important;
        }
    }
`;

const PrevItem = styled(Item)`
    a {
        color: ${({ theme }) => theme.colors.neutral[90]};
        font-weight: 600;
    }
`;

const StyledArrowRight = styled(ArrowRight)`
    margin: 0 ${({ theme }) => theme.spacing['30_Small']};
`;

const StyledHSeperator = styled(HSeperator)`
    margin: 0 1.2rem;
`;
