import { RentalUnitTypeEnum } from '../generated/graphql';

const getSqzlyCategoryNumber = (type: RentalUnitTypeEnum) => {
    switch (type) {
        case RentalUnitTypeEnum.Home:
            return 1;
        case RentalUnitTypeEnum.Tent:
            return 2;
        case RentalUnitTypeEnum.Room:
            return 3;
        case RentalUnitTypeEnum.CamperSpot:
            return 4;
        case RentalUnitTypeEnum.CampingPitch:
            return 5;
        case RentalUnitTypeEnum.Bed:
            return 6;
        default:
            return 8;
    }
};

const getSqzlyCategoryName = (prefix: string, locale: string, type: RentalUnitTypeEnum) =>
    [prefix, getSqzlyCategoryNumber(type), locale.toUpperCase()].join('');

export default getSqzlyCategoryName;
