import CircleView from '@oberoninternal/travelbase-ds/components/figure/CircleView';
import Play from '@oberoninternal/travelbase-ds/components/figure/Play';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import Cookies from 'js-cookie';
import isEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import { parse, stringifyUrl } from 'query-string';
import React, { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { jsonLdScriptProps } from 'react-schemaorg';
import styled from 'styled-components';
import { BooleanParam, decodeQueryParams, QueryParamConfigMap, StringParam, withDefault } from 'use-query-params';
import { TRAVELBASE_AFF, TRAVELBASE_AFF_REFERENCE } from '../../constants/affiliate';
import baseUrls from '../../constants/baseUrls';
import bookingParamsConfig from '../../constants/bookingParams';
import { BrandConfig } from '../../constants/brandConfig';
import getUnitTypeConsts from '../../constants/getUnitTypeConsts';
import searchParams from '../../constants/searchParams';
import { getUnitDetailSeoMessages } from '../../constants/seoMessages';
import { useTenantContext } from '../../context/TenantContext';
import { addApolloState, initApolloClient } from '../../createApolloClient';
import { Booking, bookingCartKeys } from '../../entities/Booking';
import { Locale } from '../../entities/Locale';
import NotFoundError from '../../entities/NotFoundError';
import {
    BookingCartItemInput,
    GenericImage,
    UnitDetailsDocument,
    UnitDetailsQuery,
    UnitDetailsQueryVariables,
} from '../../generated/graphql';
import useAnalyticsContentCategory from '../../hooks/analytics/useAnalyticsContentCategory';
import useAnalyticsUnitView from '../../hooks/analytics/useAnalyticsUnitView';
import { useSetCart } from '../../hooks/useCart';
import useQueryParams from '../../hooks/useQueryParams';
import useUnitDetailsData from '../../hooks/useUnitDetailData';
import useUnitSchema from '../../hooks/useUnitSchema';
import { setCartId } from '../../utils/cart';
import createImgProps from '../../utils/createImgProps';
import createPlaceholder from '../../utils/createPlaceholder';
import createUnitTitle from '../../utils/createUnitTitle';
import getDefaultAccommodationType from '../../utils/getDefaultAccommodationType';
import { groupImageByCategoryClean } from '../../utils/groupImagesByCategory';
import { convertToFilters, getAccommodationFilters } from '../../utils/search';
import setCanonicalLink from '../../utils/setCanonicalLink';
import { convertBookingToParamsInput, pickBookingFromParams, withDefaultBookingValues } from '../../utils/trip';
import ContentWrapper from '../ContentWrapper';
import BookingBar from '../designsystem/BookingBar';
import GradientBackground from '../designsystem/GradientBackground';
import Grid from '../designsystem/Grid';
import Hero, { HeroButtonsDesktop, HeroButtonsMobile, HeroContainer, HeroPhotosButton } from '../Hero';
import MediaButton from '../designsystem/MediaButton';
import PhotoModal from '../designsystem/PhotoModal';
import Section from '../designsystem/Section';
import { SectionDivider } from '../Divider';
import Extras from '../Extras';
import FullCircleModal from '../FullCircleModal';
import HeroSearchBar from '../HeroSearchBar';
import LoadingDetail from '../LoadingDetail';
import Location from '../Location';
import Page from '../Page';
import UnitAccommodation from '../UnitAccommodation';
import UnitAlternatives from '../UnitAlternatives';
import UnitAttributes from '../UnitAttributes';
import UnitAvailability from '../UnitAvailability';
import UnitBreadCrumbs from '../UnitBreadCrumbs';
import UnitIntro from '../UnitIntro';
import UnitLocationDescription from '../UnitLocationDescription';
import UnitOwner from '../UnitOwner';
import UnitParticulars from '../UnitParticulars';
import UnitPhotos from '../UnitPhotos';
import UnitRelevantAlternatives from '../UnitRelevantAlternatives';
import UnitReviews from '../UnitReviews';
import UspsContent from '../UspsContent';
import VideoModal from '../VideoModal';
import { LodgingBusiness } from 'schema-dts';
import pick from 'lodash.pick';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import RedirectModal from '../RedirectModal';
import useGetPageSlugByLocale from '../../hooks/useGetPageSlugByLocale';
import usePrevious from '../../hooks/usePrevious';

const someAmountIsDefined = (...args: Array<number | undefined>) => args.some(arg => typeof arg === 'number');

const UnitDetail: FC<React.PropsWithChildren<{ pageSlug: string }>> = ({ pageSlug }) => {
    const { setCart } = useSetCart();
    const [urlParams] = useQueryParams({
        ...searchParams,
        withHero: withDefault(BooleanParam, false),
        searched: StringParam,
    });
    const searchedParams = urlParams.searched
        ? decodeQueryParams(searchParams, parse(urlParams.searched as string))
        : null;

    const paramsInput = useMemo(() => convertBookingToParamsInput(urlParams), [urlParams]);
    useAnalyticsContentCategory('rentalUnit');

    const intl = useIntl();

    const { brandConfig } = useTenantContext();
    const { UnitInfo } = brandConfig.customContent ?? {};
    const { accommodationType = getDefaultAccommodationType(brandConfig), withHero } = urlParams;

    const { query: params, push } = useRouter();

    const { data, loading, loadingAlternatives, loadingInitialTrip } = useUnitDetailsData(
        params.slug as string,
        paramsInput
    );

    const [reserving, setReserving] = useState(false);

    // Modals
    const { open: videoModalIsOpen, onOpen: onOpenVideoModal, onClose: onCloseVideoModal } = useSesame();
    const { open: fullCircleModalIsOpen, onOpen: onOpenFullCircleModal, onClose: onCloseFullCircleModal } = useSesame();
    const { open: plannerModalOpen, onOpen: onOpenPlannerModal, onClose: onClosePlannerModal } = useSesame();
    const { open: redirectModalOpen, onOpen: onOpenRedirectModal, onClose: onCloseRedirectModal } = useSesame();
    const [redirectModalProps, setRedirectModalProps] = useState<
        Partial<Pick<ComponentProps<typeof RedirectModal>, 'redirectUrl' | 'brand'>>
    >({});
    const { open: openPhotos, onClose: onClosePhotos, onOpen: onOpenPhotos } = useSesame();

    const [startIndex, setStartIndex] = useState(0);

    const rentalUnit = data?.rentalUnit;

    const [bookingParams, setParams] = useQueryParams({ ...bookingParamsConfig, specialId: StringParam });
    const [booking, setBooking] = useState<Booking>(bookingParams);
    const onBookingChange = useCallback(
        (newBooking: Booking) => {
            const { trip, ...rest } = newBooking;

            setBooking(current => {
                const mergedBooking = { ...current, ...newBooking };

                if (isEqual(current, mergedBooking)) {
                    // bail on update if there's nothing to update
                    return current;
                }

                // we reflect the params in the url as well
                setParams({ ...rest, specialId: trip?.specialId ?? undefined }, 'replaceIn');

                return mergedBooking;
            });
        },
        [setParams]
    );

    const prevSlug = usePrevious(params.slug);
    useEffect(
        () => {
            if (prevSlug !== params.slug) {
                setBooking({
                    ...bookingParams,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params.slug]
    );

    useEffect(() => {
        if (data.initialTrip) {
            setBooking(current => ({
                ...current,
                trip: data.initialTrip,
            }));
        }
    }, [data.initialTrip]);

    useEffect(() => {
        if (
            !someAmountIsDefined(booking.amountAdults, booking.amountChildren, booking.amountYouths) &&
            data.rentalUnit
        ) {
            setBooking(current => ({
                ...current,
                amountAdults: data.rentalUnit?.minOccupancy,
            }));
        }
    }, [booking, data.rentalUnit, onBookingChange]);

    const onBookingReservation = useCallback(async () => {
        setReserving(true);
        const bookingValues = withDefaultBookingValues(booking);
        const { trip } = bookingValues;

        if (!trip || !data?.rentalUnit?.id) {
            setReserving(false);
            return;
        }

        const input: BookingCartItemInput = {
            ...pick(bookingValues, bookingCartKeys),
            cancellationInsurance: data.rentalUnit.cancellationInsuranceAvailable
                ? brandConfig.cancellationInsuranceDefault
                : false,
            contentsInsurance: false,
            optionalSurcharges: [],
            rentalUnitId: data?.rentalUnit?.id,
            specialId: trip.specialId,
        };
        if (brandConfig.checkout?.checkoutShouldRedirect && !!rentalUnit?.brand) {
            const affiliateHandleCookie = Cookies.get(TRAVELBASE_AFF);
            let affiliateHandle = Cookies.get(TRAVELBASE_AFF);
            let affiliateReference = Cookies.get(TRAVELBASE_AFF_REFERENCE);

            if (brandConfig.forceAffiliate) {
                affiliateReference = undefined;

                // "Er nog geen affiliate (travelbase_aff) in de cookie zit, dan de force affiliate als affiliateHandle meegeven bij de checkout (zonder affiliateReference)"
                if (!affiliateHandleCookie) {
                    affiliateHandle = brandConfig.forceAffiliate;
                }

                // "Er wel een affiliate in de cookie zit, maar die niet overeen komt met de force affiliate, dan de force affiliate doorgeven aan de checkout (zonder affiliateReference)"
                if (affiliateHandleCookie && affiliateHandleCookie !== brandConfig.forceAffiliate) {
                    affiliateHandle = brandConfig.forceAffiliate;
                }

                // "Als dezelfde force affiliate al bestaat, dan deze ook doorgeven aan de checkout, samen met de bestaande travelbase_aff_reference als affiliateReference doorgeven aan de checkout."
                if (affiliateHandleCookie && affiliateHandleCookie === brandConfig.forceAffiliate) {
                    affiliateReference = Cookies.get(TRAVELBASE_AFF_REFERENCE);
                }
            }
            const query: Record<string, string> = {};
            if (affiliateReference) {
                query.affiliateReference = affiliateReference;
            }
            if (affiliateHandle) {
                query.affiliateHandle = affiliateHandle;
            }
            const redirectUrl = stringifyUrl({
                url: `${baseUrls[rentalUnit.brand as keyof typeof baseUrls]}/checkout/redirect`,
                query: {
                    specialId: trip.specialId ?? undefined,
                    rentalUnitSlug: rentalUnit?.slug,
                    ...query,
                    ...params,
                },
            });

            setRedirectModalProps({ brand: rentalUnit.brand, redirectUrl });
            onOpenRedirectModal();

            // redirecting - don't set cart, that happens over on the redirected site.
            setReserving(false);
            return;
        }

        try {
            const result = await setCart({ bookings: [input] });
            const { id: cartId } = result?.data?.updateCart.cart ?? {};

            if (!cartId) {
                // TODO: https://teamoberon.atlassian.net/browse/T0-1266
                throw new Error('Something went wrong');
            }

            setCartId(cartId);
            await push('/checkout/extras');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            setReserving(false);
        }
    }, [
        booking,
        data.rentalUnit?.id,
        data.rentalUnit?.cancellationInsuranceAvailable,
        brandConfig.cancellationInsuranceDefault,
        brandConfig.checkout?.checkoutShouldRedirect,
        brandConfig.forceAffiliate,
        rentalUnit?.brand,
        rentalUnit?.slug,
        params,
        onOpenRedirectModal,
        setCart,
        push,
    ]);

    const unitSchema = useUnitSchema(rentalUnit);
    useAnalyticsUnitView(rentalUnit, booking);

    const { getPageSlugByLocale } = useGetPageSlugByLocale();
    const expectedSlug = getPageSlugByLocale('ACCOMMODATION');
    if (pageSlug !== expectedSlug) {
        throw new NotFoundError(`slug "${pageSlug}" does not match expected "${expectedSlug}"`);
    }

    if (loading) {
        return <LoadingDetail withHero={withHero} />;
    }

    if (!rentalUnit) {
        throw new NotFoundError();
    }

    const {
        name,
        reviewStats,
        accommodation: {
            name: accommodationName,
            hasPublicPage,
            ownerIntroduction,
            jpegMain,
            webpMain,
            descriptionGeneral,
            slug: accommodationSlug,
            jpegOwner,
            webpOwner,
            ownerName,
            ownerTips,
        },
        jpegThumbnail,
        webpThumbnail,
        webpHero,
        jpegHero,
        maxOccupancy,
        minOccupancy,
        petsAllowed,
        type,
        fullCircleImageUrl,
        videoUrl,
        hasBakedInFilterProperty,
        firstTripDate,
    } = rentalUnit;
    const mergedImages = rentalUnit.images.concat(rentalUnit.accommodation.images);
    const renderHero = (webp: GenericImage, jpeg: GenericImage, className?: string) => (
        <Hero
            ratio={9 / 16}
            className={className}
            placeholder={createPlaceholder(webp.transform?.placeholder)}
            sources={[
                {
                    srcSet: webp.transform?.srcSet,
                    type: 'image/webp',
                },
                {
                    srcSet: jpeg.transform?.srcSet,
                    type: 'image/jpeg',
                },
            ]}
            fallback={{
                src: jpeg.transform?.src,
                alt: rentalUnit?.tagline,
            }}
        >
            <HeroButtonsMobile>
                {videoUrl && (
                    <MediaButton icon={<Play />} onClick={() => onOpenVideoModal()}>
                        <FormattedMessage defaultMessage="Video" />
                    </MediaButton>
                )}
                {fullCircleImageUrl && (
                    <MediaButton icon={<CircleView />} onClick={() => onOpenFullCircleModal()}>
                        <FormattedMessage defaultMessage="360°" />
                    </MediaButton>
                )}
                {mergedImages.length > 0 && (
                    <HeroPhotosButton onClick={onOpenPhotos}>{mergedImages.length}</HeroPhotosButton>
                )}
            </HeroButtonsMobile>
            {(videoUrl || fullCircleImageUrl) && (
                <HeroButtonsDesktop>
                    {videoUrl && (
                        <MediaButton icon={<Play />} onClick={() => onOpenVideoModal()}>
                            <FormattedMessage defaultMessage="Video bekijken" />
                        </MediaButton>
                    )}
                    {fullCircleImageUrl && (
                        <MediaButton
                            icon={<CircleView />}
                            onClick={() => onOpenFullCircleModal()}
                            style={{ marginLeft: '4px' }}
                        >
                            <FormattedMessage defaultMessage="360° bekijken" />
                        </MediaButton>
                    )}
                </HeroButtonsDesktop>
            )}
        </Hero>
    );
    const hasAlternatives =
        rentalUnit.alternativesInAccommodation && rentalUnit.alternativesInAccommodation.hits.length > 0;

    const { title, description } = getUnitDetailSeoMessages(rentalUnit, intl);

    return (
        <>
            <Head>
                <title>{`${title} - ${brandConfig.seo.siteName}`}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={rentalUnit?.jpegThumbnail?.transform?.src} />
                <meta property="og:image:alt" content={rentalUnit?.tagline} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={rentalUnit?.jpegThumbnail?.transform?.src} />
                <meta name="twitter:image:alt" content={rentalUnit?.tagline} />
                {brandConfig.bakedInFilterProperty && !hasBakedInFilterProperty && (
                    // hide from search engines if we're using a baked-in property and the rentalUnit does not have said property.
                    <meta name="robots" content="noindex" />
                )}
                <script {...jsonLdScriptProps<LodgingBusiness>(unitSchema)} />
            </Head>
            <StyledPage>
                {videoUrl && <VideoModal open={videoModalIsOpen} onClose={onCloseVideoModal} src={videoUrl} />}
                {fullCircleImageUrl && (
                    <FullCircleModal
                        open={fullCircleModalIsOpen}
                        onClose={onCloseFullCircleModal}
                        src={fullCircleImageUrl}
                    />
                )}
                {redirectModalProps.brand && redirectModalProps.redirectUrl && (
                    <RedirectModal
                        brand={redirectModalProps.brand}
                        redirectUrl={redirectModalProps.redirectUrl}
                        onClose={onCloseRedirectModal}
                        open={redirectModalOpen}
                    />
                )}
                <HeroContainer>
                    <HeroSearchBar
                        hasHero={!!(webpHero && jpegHero)}
                        initialValues={{
                            accommodationType,
                            booking: searchedParams ?? pickBookingFromParams(urlParams),
                        }}
                    />
                    {webpHero && jpegHero
                        ? renderHero(webpHero, jpegHero)
                        : renderHero(webpThumbnail, jpegThumbnail, 'lt-s')}
                </HeroContainer>
                <BookingBar
                    reserving={reserving}
                    booking={booking}
                    onBookingReservation={onBookingReservation}
                    plannerModalIsOpen={plannerModalOpen}
                    openPlannerModal={onOpenPlannerModal}
                    closePlannerModal={onClosePlannerModal}
                    loading={loadingInitialTrip}
                    data={{
                        type: 'unit',
                        rating: reviewStats?.count ? reviewStats?.average : undefined,
                        title: name,
                        strapline: (hasPublicPage && accommodationName) || undefined,
                        thumbnail: {
                            loading: 'eager',
                            sources: [
                                {
                                    srcSet: webpThumbnail.transform?.srcSet,
                                    type: 'image/webp',
                                },
                                {
                                    srcSet: jpegThumbnail.transform?.srcSet,
                                    type: 'image/jpeg',
                                },
                            ],
                            fallback: {
                                src: jpegThumbnail.transform?.src,
                            },
                            placeholder: createPlaceholder(webpThumbnail.transform?.placeholder),
                        },
                        owner: ownerIntroduction,
                    }}
                />
                <Section name="intro">
                    <UnitBreadCrumbs data={rentalUnit} />
                    <UnitIntro data={rentalUnit} />
                    {UnitInfo && <UnitInfo />}
                    <ContentWrapper>
                        {mergedImages.length > 0 && (
                            <UnitPhotos
                                data={mergedImages}
                                onCategoryClick={categoryIndex => {
                                    onOpenPhotos();
                                    setStartIndex(categoryIndex);
                                }}
                                onOpenPhotos={() => {
                                    onOpenPhotos();
                                }}
                            />
                        )}
                    </ContentWrapper>
                    <PhotoModal
                        imagesOrCategories={groupImageByCategoryClean(mergedImages, true)}
                        open={openPhotos}
                        onClose={onClosePhotos}
                        startIndex={startIndex}
                    />
                    <UnitAttributes
                        data={rentalUnit.attributeCategories}
                        name={getUnitTypeConsts(intl)[type].translation}
                        registrationNumber={rentalUnit.municipalRegistration}
                    />

                    {hasAlternatives && <UnitAlternatives rentalUnit={rentalUnit} loading={loadingAlternatives} />}
                    {!hasAlternatives && hasPublicPage && (
                        <UnitAccommodation
                            data={{
                                jpegMain,
                                webpMain,
                                descriptionGeneral,
                                name: accommodationName,
                                slug: accommodationSlug,
                            }}
                        />
                    )}
                </Section>
                <UnitSection name="location">
                    <Location data={rentalUnit.accommodation} />
                </UnitSection>
                {rentalUnit.descriptionExtras && (
                    <UnitSection name="extras">
                        <Extras data={rentalUnit.attributeCategories} description={rentalUnit.descriptionExtras} />
                    </UnitSection>
                )}
                <SectionDivider />
                <UnitSection name="particulars">
                    <UnitParticulars data={rentalUnit} />
                </UnitSection>
                <StyledSectionDivider />

                <UnitSection name="price">
                    <UnitAvailability
                        reserving={reserving}
                        booking={booking}
                        onBookingChange={onBookingChange}
                        onBookingReservation={onBookingReservation}
                        id={rentalUnit.id}
                        slug={params.slug as string}
                        open={plannerModalOpen}
                        onClose={onClosePlannerModal}
                        onOpen={onOpenPlannerModal}
                        name={createUnitTitle(rentalUnit.name, accommodationName, hasPublicPage)}
                        minOccupancy={minOccupancy}
                        maxOccupancy={maxOccupancy}
                        petsAllowed={petsAllowed}
                        firstTripDate={firstTripDate ? new Date(firstTripDate) : undefined}
                        type="unit"
                        loading={loadingInitialTrip}
                    />
                </UnitSection>
                <Section name={`${rentalUnit.reviewStats ? 'reviews' : 'owner'}`}>
                    {rentalUnit.reviewStats && <ReviewsGradient />}
                    {!rentalUnit.reviewStats && <SectionDivider />}
                    <ContentWrapper>
                        <Grid>
                            {rentalUnit.reviewStats && (
                                <UnitReviews
                                    reviews={rentalUnit.reviews ?? []}
                                    reviewStats={rentalUnit.reviewStats}
                                    slug={params.slug as string}
                                    ownerName={ownerName}
                                    ownerImage={jpegOwner && webpOwner && createImgProps(jpegOwner, webpOwner)}
                                />
                            )}
                            {(ownerIntroduction || ownerTips) && rentalUnit ? (
                                <UnitOwner
                                    data={rentalUnit.accommodation}
                                    hasReviews={!!rentalUnit.reviewStats?.count}
                                />
                            ) : (
                                <UnitLocationDescription
                                    brand={rentalUnit.brand}
                                    hasReviews={!!rentalUnit.reviewStats?.count}
                                />
                            )}
                        </Grid>
                    </ContentWrapper>
                </Section>
                <UnitRelevantAlternatives
                    params={{
                        ...paramsInput,
                        filters: convertToFilters([getAccommodationFilters(accommodationType), 'OR']),
                    }}
                />
            </StyledPage>
            <UspsContent />
        </>
    );
};

export const getServerSideProps = async (
    brandConfig: BrandConfig,
    ctx: Parameters<GetServerSideProps>[0]
): ReturnType<GetServerSideProps> => {
    const { query, params, resolvedUrl, res, req, locale } = ctx;

    setCanonicalLink(req, res);

    const client = initApolloClient(locale as Locale);

    const decodedParams = decodeQueryParams(searchParams as QueryParamConfigMap, query);

    const slug = params?.slug as string;

    // redirect legacy slugs
    if (slug.includes('_')) {
        return {
            redirect: {
                permanent: true,
                destination: resolvedUrl.replace(slug, slug.replace(/_/g, '-')),
            },
        };
    }

    const result = await client.query<UnitDetailsQuery, UnitDetailsQueryVariables>({
        query: UnitDetailsDocument,
        variables: {
            slug,
            arrivalDate: decodedParams.arrivalDate ?? '',
            bakedInFilterProperty: brandConfig.bakedInFilterProperty ?? '',
        },
    });

    // force react to create a new instance of the component if its rentalUnit id is different instead of using the same one
    // When using the same, images glitch briefly by using stale data.
    const props: { id?: string } = {};

    if (result.data.rentalUnit) {
        props.id = result.data.rentalUnit.id;
    } else {
        return {
            notFound: true,
        };
    }

    return addApolloState(client, { props });
};

const ReviewsGradient = styled(GradientBackground).attrs({ variant: 'neutral' })`
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        :before {
            width: calc((100vw - 152rem) / 2 + 81.8rem);
        }
    }
`;

const StyledPage = styled(Page)`
    padding-bottom: 8rem;
`;

export const UnitSection = styled(Section)`
    --sectionPadding: min(8rem, var(--wrapperPadding));
`;

const StyledSectionDivider = styled(SectionDivider)`
    display: none;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: block;
    }
`;

export default UnitDetail;
