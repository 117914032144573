import { BaseImgProps } from '../Img';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Booking } from '../../entities/Booking';
import Rating from '../Rating';
import { StickyBarThumnail } from '../StickyBar';
import UnitStickyBar from '../UnitStickyBar';
import BookButton from './BookButton';
import Nav from './Nav';

export interface BookingBarData {
    type: 'accommodation' | 'unit';
    rating?: number;
    title: string;
    strapline?: string;
    thumbnail: BaseImgProps;
    owner: Maybe<string>;
}
interface Props {
    data?: BookingBarData;
    openPlannerModal: () => void;
    closePlannerModal: () => void;
    plannerModalIsOpen: boolean;
    onBookingReservation: () => void;
    booking: Booking;
    reserving: boolean;
    loading?: boolean;
}

const BookingBar: FC<React.PropsWithChildren<Props>> = ({
    data,
    openPlannerModal,
    booking,
    onBookingReservation,
    reserving,
    closePlannerModal,
    plannerModalIsOpen,
    loading,
}) => {
    const { arrivalDate, departureDate, trip } = booking;
    const bookingIsValid = !!(arrivalDate && departureDate);
    const { type = 'unit', owner, rating } = data ?? {};
    return (
        <UnitStickyBar
            booking={booking}
            openModal={openPlannerModal}
            plannerIsOpen={plannerModalIsOpen}
            loading={loading}
            confirmButton={
                <BookingButton
                    size="large"
                    submitting={reserving}
                    disabled={plannerModalIsOpen && !trip}
                    onClick={trip ? onBookingReservation : openPlannerModal}
                    data-cy="bookingButton"
                >
                    {bookingIsValid && type === 'unit' && <FormattedMessage defaultMessage="Reserveren" />}
                    {!bookingIsValid && type === 'unit' && (
                        <FormattedMessage defaultMessage="Beschikbaarheid en prijzen" />
                    )}
                    {type === 'accommodation' && <FormattedMessage defaultMessage="Kies een verblijf" />}
                </BookingButton>
            }
        >
            <StickyBarThumnail className="gt-l" subTitle={rating ? <Rating value={rating} /> : undefined} {...data} />
            <Nav
                delay={plannerModalIsOpen ? 500 : undefined}
                onClick={closePlannerModal}
                className="gt-s"
                items={[
                    {
                        label: <FormattedMessage defaultMessage="Overzicht" />,
                        name: 'intro',
                    },
                    {
                        label: <FormattedMessage defaultMessage="Ligging" />,
                        name: 'location',
                    },
                    ...(type === 'unit'
                        ? [
                              {
                                  label: <FormattedMessage defaultMessage="Extra's" />,
                                  name: 'extras',
                                  className: 'gt-m',
                              },
                              {
                                  label: <FormattedMessage defaultMessage="Prijs" />,
                                  name: 'price',
                              },
                              ...(rating || owner
                                  ? [
                                        {
                                            label: rating ? (
                                                <FormattedMessage defaultMessage="Beoordelingen" />
                                            ) : (
                                                <FormattedMessage defaultMessage="Eigenaar" />
                                            ),
                                            name: `${rating ? 'reviews' : 'owner'}`,
                                            className: 'gt-m',
                                        },
                                    ]
                                  : []),
                          ]
                        : []),
                ]}
            />
        </UnitStickyBar>
    );
};

export default BookingBar;

const BookingButton = styled(BookButton)`
    margin-left: auto;
    flex-grow: 1;
`;
